@import 'appeals/appeals';
@import 'fix/fix';
@import 'import/csv/preview/preview';
@import 'merge/merge';
@import 'sidebar/sidebar';
tools {
    display: grid;
    height: 100%;
    overflow: hidden;
    grid-template-columns: auto $fr-1;
    > tools-sidebar {
        overflow: auto;
        grid-column: 1;
        grid-row: 1;
        &.no-sidebar {
            display: none;
        }
    }
    > ui-view {
        padding: 10px $container-padding 60px;
        overflow: auto;
        grid-row: 1;
        grid-column: 2;
        &.no-sidebar {
            padding: 0;
            grid-column: 1 / span 2;
        }
        > .panel__prompt {
            margin: 10px $container-padding 0;
        }
    }
    .tools {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px;
        .tool {
            padding: 25px;
            border: 1px solid $color-border;
            background: color-gray(95);
            font-size: $font-size-sm;
            text-align: center;
            cursor: pointer;
            .title {
                margin: 12px auto;
                text-transform: none;
            }
            .icon {
                position: relative;
                width: 80px;
                height: 80px;
                margin: 0 auto;
                padding: 20px;
                border: 0;
                border-radius: 60px;
                background: $color-reverse;
                color: $color-text;
                font-size: 30px;
                line-height: 40px;
                text-align: center;
                .badge {
                    position: absolute;
                    top: 0;
                    right: 0;
                    border: 1px solid $color-secondary;
                    background: $color-reverse;
                    color: $color-secondary;
                }
            }
            &.selected {
                border: 1px solid $color-secondary;
                background: $color-secondary-light;
                .icon {
                    background: $color-secondary;
                    color: $color-reverse;
                }
            }
        }
    }
    h1.heading {
        margin: 0 -15px 15px;
        padding: 11px 15px 15px;
        border-bottom: 1px solid $color-border;
        font-size: $font-size-lg;
    }
    .bulk {
        margin-bottom: $container-padding;
        .chosen-container {
            width: 150px !important;
        }
        .well {
            max-width: 400px;
            margin: 40px 0;
            border-style: dashed;
            text-align: center;
            cursor: auto;
        }
    }
}
