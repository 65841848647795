/*!
 * Chosen Bootstrap Look & Feel
 * Based on bootstrap-chosen (https://github.com/alxlit/bootstrap-chosen)
 * @author İsmail Demirbilek (@dbtek)
 * https://github.com/dbtek/chosen-bootstrap
 */
$chosen-sprite: '../node_modules/chosen-js/chosen-sprite.png';
.chosen-select {
    width: 100%;
}
.chosen-select-deselect {
    width: 100%;
}
.chosen-container {
    display: inline-block;
    position: relative;
    width: 100% !important;
    font-size: $font-size-sm;
    vertical-align: middle;
    .chosen-drop {
        position: absolute;
        top: 100%;
        left: -9000px;
        margin-top: -1px;
        border: 1px solid $color-blue-deep-alpha-80;
        border-top: 0;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        background: $color-white;
        box-shadow:
            0 1px 1px $color-black-alpha-10 inset,
            0 4px 8px $color-blue-deep-alpha-60;
    }
    .chosen-results {
        position: relative;
        max-height: 240px;
        margin: 0 4px 4px 0;
        padding: 0 0 0 4px;
        color: color-gray(30);
        overflow-x: hidden;
        overflow-y: auto;
        li {
            display: none;
            margin: 0;
            padding: 5px 6px;
            line-height: 1.428571429;
            list-style: none;
            em {
                background: $color-yellow-light;
                font-style: normal;
            }
        }
        li.group-result {
            display: list-item;
            color: color-gray(60);
            font-weight: bold;
            cursor: default;
        }
        li.group-option {
            padding-left: 15px;
        }
        li.active-result {
            display: list-item;
            cursor: pointer;
        }
        li.highlighted {
            background-color: $color-blue-light;
            color: $color-white;
            em {
                background: transparent;
            }
        }
        li.disabled-result {
            display: list-item;
            color: color-gray(60);
        }
        .no-results {
            display: list-item;
            background: color-gray(95);
        }
    }
    .chosen-results-scroll {
        position: absolute;
        width: 321px;
        margin: 0 4px;
        background: $color-white;
        text-align: center;
        span {
            display: inline-block;
            width: 9px;
            height: 1.428571429;
            text-indent: -5000px;
        }
    }
    .chosen-results-scroll-down {
        bottom: 0;
        span {
            background: url($chosen-sprite) no-repeat -4px -3px;
        }
    }
    .chosen-results-scroll-up {
        span {
            background: url($chosen-sprite) no-repeat -22px -3px;
        }
    }
}
.chosen-container.chosen-with-drop {
    .chosen-drop {
        right: 0;
        left: 0;
    }
}
.chosen-container-single {
    .chosen-single {
        display: block;
        position: relative;
        height: 34px;
        padding: 0 0 0 8px;
        border: 1px solid color-gray(80);
        border-radius: $border-radius;
        background-color: inherit;
        background-repeat: repeat-x;
        color: color-gray(30);
        line-height: 34px;
        text-decoration: none;
        white-space: nowrap;
        box-shadow: inset 0 1px 1px $color-black-alpha-10;
        overflow: hidden;
        span {
            display: block;
            margin-right: 26px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        abbr {
            display: block;
            position: absolute;
            top: 12px;
            right: 26px;
            width: 12px;
            height: 10px;
            background: url($chosen-sprite) right top no-repeat;
            font-size: 1px;
            &:hover {
                background-position: right -11px;
            }
        }
        div {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 18px;
            height: 100%;
            b {
                display: block;
                width: 100%;
                height: 100%;
                background: url($chosen-sprite) no-repeat 0 7px;
            }
        }
        input.chosen-focus-input {
            display: none; // odd side effect of unknown origin
        }
    }
    .chosen-single.chosen-disabled {
        .chosen-single {
            abbr {
                &:hover {
                    background-position: right 2px;
                }
            }
        }
    }
    .chosen-default {
        color: color-gray(60);
    }
    .chosen-search {
        position: relative;
        margin: 0;
        padding: 3px 4px;
        white-space: nowrap;
        input {
            width: 100%;
            height: 25px;
            margin: 1px 0;
            padding: 4px 20px 4px 4px;
            border: 1px solid color-gray(80);
            border-radius: $border-radius;
            background: url($chosen-sprite) no-repeat 100% -20px;
        }
    }
    .chosen-drop {
        margin-top: -1px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        background-clip: padding-box;
    }
}
.chosen-container-single-nosearch {
    .chosen-search {
        input {
            position: absolute;
            left: -9000px;
        }
    }
}
.chosen-container-multi {
    .chosen-choices {
        position: relative;
        height: auto !important;
        margin: 0;
        padding: 0;
        border: 1px solid color-gray(80);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        background-color: $color-white;
        box-shadow: inset 0 1px 1px $color-black-alpha-10;
        cursor: text;
        overflow: hidden;
        li {
            float: left;
            list-style: none;
        }
        .search-field {
            margin: 0;
            padding: 0;
            white-space: nowrap;
            input {
                height: 32px;
                margin: 0;
                padding: 4px;
                border: 0 !important;
                outline: 0;
                background: transparent !important;
                color: color-gray(30);
                box-shadow: none;
            }
            .default {
                color: color-gray(60);
            }
        }
        .search-choice {
            position: relative;
            margin: 6px 3px 3px 5px;
            padding: 3px 20px 3px 5px;
            border: 1px solid color-gray(80);
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            background-clip: padding-box;
            background-color: color-gray(95);
            background-repeat: repeat-x;
            color: color-gray(20);
            line-height: 13px;
            box-shadow: inset 0 1px 1px $color-black-alpha-10;
            cursor: default;
            .search-choice-close {
                display: block;
                position: absolute;
                top: 5px;
                right: 4px;
                width: 12px;
                height: 10px;
                background: url($chosen-sprite) right top no-repeat;
                font-size: 1px;
                &:hover {
                    background-position: right -11px;
                }
            }
        }
        .search-choice-focus {
            background: color-gray(85);
            .search-choice-close {
                background-position: right -11px;
            }
        }
    }
    .chosen-results {
        margin: 0;
        padding: 0;
    }
    .chosen-drop {
        .result-selected {
            display: none;
        }
    }
}
// CUSTOM STYLES
.chosen-container-active {
    .chosen-single {
        transition:
            border linear 0.2s,
            box-shadow linear 0.2s;
        border: 1px solid $color-blue-deep-alpha-80;
        box-shadow:
            0 1px 1px $color-black-alpha-10 inset,
            0 0 8px $color-blue-deep-alpha-60;
    }
    .chosen-choices {
        transition:
            border linear 0.2s,
            box-shadow linear 0.2s;
        border: 1px solid $color-blue-deep-alpha-80;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        box-shadow:
            0 1px 1px $color-black-alpha-10 inset,
            0 0 8px $color-blue-deep-alpha-60;
        .search-field {
            input {
                color: color-gray(10) !important;
            }
        }
    }
    > .chosen-single,
    > .chosen-drop,
    &.chosen-with-drop > .chosen-single {
        outline: none;
        box-shadow: none;
    }
    .chosen-search input {
        border-radius: 0;
        &:focus {
            border-color: $color-blue-deep-alpha-80;
            outline: none;
            box-shadow: none;
        }
    }
}
.chosen-container-active.chosen-with-drop {
    .chosen-single {
        transition:
            border linear 0.2s,
            box-shadow linear 0.2s;
        border: 1px solid $color-blue-deep-alpha-80;
        border-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow:
            0 1px 1px $color-black-alpha-10 inset,
            0 0 8px $color-blue-deep-alpha-60;
        div {
            border-left: 0;
            background: transparent;
            b {
                background-position: -18px 7px;
            }
        }
    }
    .chosen-container-multi {
        transition:
            border linear 0.2s,
            box-shadow linear 0.2s;
        border: 1px solid $color-blue-deep-alpha-80;
        border-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow:
            0 1px 1px $color-black-alpha-10 inset,
            0 0 8px $color-blue-deep-alpha-60;
    }
}
.chosen-disabled {
    cursor: default;
    opacity: 0.5 !important;
    .chosen-single {
        cursor: default;
    }
    .chosen-choices {
        .search-choice {
            .search-choice-close {
                cursor: default;
            }
        }
    }
}
.chosen-rtl {
    text-align: right;
    .chosen-single {
        padding: 0 8px 0 0;
        overflow: visible;
        span {
            margin-right: 0;
            margin-left: 26px;
            direction: rtl;
        }
        div {
            right: auto;
            left: 7px;
        }
        abbr {
            right: auto;
            left: 26px;
        }
    }
    .chosen-choices {
        .search-field {
            input {
                direction: rtl;
            }
        }
        li {
            float: right;
        }
        .search-choice {
            margin: 6px 5px 3px 0;
            padding: 3px 5px 3px 19px;
            .search-choice-close {
                right: auto;
                left: 4px;
                background-position: right top;
            }
        }
    }
    .chosen-results {
        .group-option {
            padding-right: 15px;
            padding-left: 0;
        }
    }
    .chosen-search {
        input {
            padding: 4px 5px 4px 20px;
            background:
                url($chosen-sprite) no-repeat -28px -20px,
                $color-white;
            direction: rtl;
        }
    }
}
.chosen-rtl.chosen-container-single {
    .chosen-results {
        margin: 0 0 4px 4px;
        padding: 0 4px 0 0;
    }
}
.chosen-rtl.chosen-container-active.chosen-with-drop {
    .chosen-single {
        div {
            border-right: 0;
        }
    }
}
