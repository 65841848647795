tasks-list-item {
    div.item {
        display: flex;
        align-items: center;
        height: 50px;
        padding: 10px 15px;
        transition: background-color 0.2s;
        background-color: $color-white;
        font-size: $font-size-sm;
        line-height: 30px;
        white-space: nowrap;
        cursor: pointer;
        grid-template-columns: 14px 30px auto auto 45px 52px 20px 20px;
        &:hover {
            background-color: $color-list-item-hover;
            > div.trash {
                @extend .fade-in, .ng-enter;
                opacity: 1;
            }
            > .select-box {
                border-color: $color-blue;
            }
        }
        > .select-box {
            flex: 0 0 11px;
            height: 11px;
            transition:
                background-color 0.2s ease-out,
                border-color 0.2s ease-out;
            border: 1px solid transparent;
            border-radius: 3px;
            line-height: 22px;
            &.selected {
                border-color: $color-blue;
                background-color: $color-blue;
            }
        }
        > a.complete {
            display: block;
            padding-left: 5px;
            font-size: $font-size-md;
            line-height: 25px;
            &:hover {
                color: $color-success;
                > i {
                    font-weight: 900;
                    &::before {
                        content: '\F058';
                    }
                }
            }
        }
        > div.content {
            flex-grow: 1;
            padding-left: 5px;
            text-overflow: ellipsis;
            overflow: hidden;
            > span.contacts {
                margin-left: 5px;
            }
        }
        > div.tags {
            flex-grow: 1;
            padding-left: 5px;
            text-align: right;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        > div.date {
            padding-left: 5px;
        }
        > div.comments {
            padding-left: 5px;
            text-align: center;
            .btn {
                width: 51px;
            }
        }
        > a.star {
            font-size: $font-size-md;
            &:hover {
                > i {
                    font-weight: 900;
                }
            }
        }
        > div.trash {
            @extend .fade-in, .ng-leave;
            width: 15px;
            margin: 0 10px;
            font-size: $font-size-md;
            opacity: 0;
            &:hover {
                > a > i {
                    color: $color-danger;
                }
            }
        }
        .tag {
            margin-top: 0;
        }
        &--selected {
            background-color: $color-list-item-active;
            &:hover {
                background-color: $color-list-item-active-hover;
            }
        }
    }
}
