designation-accounts {
    .organization {
        margin: 0 (-$container-padding);
        &:first-child {
            margin-top: -$container-padding;
        }
        .title {
            display: grid;
            padding: 10px $container-padding;
            border-bottom: 1px solid $color-border;
            font-size: $font-size-sm;
            font-weight: bold;
            grid-template-columns: 1fr auto auto;
            .active {
                width: 40px;
                margin-left: 25px;
                text-align: center;
            }
        }
    }
    .designation-account {
        display: grid;
        align-items: center;
        padding: 15px $container-padding;
        border-bottom: 1px solid $color-border;
        grid-template-columns: 1fr auto auto;
        grid-row-gap: 15px;
        .details {
            .name {
                font-size: $font-size-ml;
            }
            a:hover {
                text-decoration: none;
            }
        }
        .balances {
            text-align: right;
            .balance {
                font-size: $font-size-ml;
            }
        }
        .chart {
            grid-column: 1 / span 3;
        }
        fa-checkbox {
            margin-left: 25px;
        }
    }
}
