@import 'person/person';
contact-info {
    > div.header {
        display: grid;
        align-items: center;
        height: 61px;
        padding: 0 $container-padding;
        border-bottom: 1px solid color-gray(90);
        background-color: $color-blue-lighter;
        grid-gap: 10px;
        grid-template-columns: 38px 1fr 18px auto;
        avatar {
            grid-column: 1;
            &,
            .avatar,
            .avatar img {
                width: 38px;
                height: 38px;
            }
        }
        > div.inline-input {
            margin-bottom: 0;
            grid-column: 2;
            input {
                border-bottom-color: transparent;
                &:focus {
                    border-bottom-color: $color-blue;
                }
            }
        }
        > div.star {
            grid-column: 3;
        }
        > div.contact-actions {
            grid-column: 4;
        }
    }
    > div.content {
        display: grid;
        padding: 10px $container-padding;
        background-color: $color-blue-lighter;
        font-weight: 600;
        grid-template-rows: repeat(3, 50px);
        grid-template-columns: repeat(3, 1fr) 210px;
        grid-gap: 10px;
        > div {
            @extend .inline-input;
        }
        label {
            @extend h4;
            display: block;
            margin: 0 0 3px;
            padding-right: 4px;
            color: color-gray(60);
            font-size: $font-size-xs;
        }
        > div.person {
            grid-row: 1;
            grid-column: 1 / span 3;
        }
        > div.address {
            color: color-gray(35);
            font-size: $font-size-sm;
            grid-row: 1 / span 2;
            grid-column: 4;
            label {
                margin-bottom: 6px;
            }
        }
        > div.commitment input {
            &:disabled {
                &:hover {
                    cursor: not-allowed;
                }
            }
        }
        > div.frequency .chosen-disabled .chosen-single {
            &:hover {
                cursor: not-allowed;
            }
        }
    }
    contact-address {
        .wrapper {
            flex-direction: column;
            align-items: flex-start;
            padding-top: 0;
            border: 0;
            .content {
                flex-grow: 0;
                line-height: $font-size-sm * 1.8;
                h3 {
                    display: block;
                    max-height: 45px;
                    margin-bottom: 3px;
                    font-size: $font-size-sm;
                    font-weight: 600;
                    overflow: hidden;
                }
                .source {
                    display: none;
                }
            }
            .actions {
                a i {
                    margin-right: 2px;
                    font-size: $font-size-xs;
                }
                .primary {
                    display: none;
                }
            }
        }
    }
    .commitment {
        // sass-lint:disable-block no-vendor-prefixes
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            margin: 0;
            appearance: none;
        }
    }
}
