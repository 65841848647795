reports-sidebar {
    @extend .sidebar-expandable;
    > .content {
        @extend .sidebar;
        padding: 0;
    }
    .filter {
        height: auto;
        border-bottom: 1px solid $color-gray-blue;
    }
    .filters_section:last-child {
        border-bottom: 0;
    }
    .report-filter {
        padding: 10px 0;
        border-top: 1px solid $color-gray-blue;
    }
    label {
        font-weight: normal;
    }
}
