@import 'item/item';
@import 'search/search';
$search-bar-width: 275px;
contacts-list {
    @extend .lists;
    display: grid;
    height: $full-container-height;
    overflow: hidden;
    grid-template-rows: auto auto $fr-1;
    > div.menu {
        grid-row: 1;
        grid-column: 1;
        grid-column-gap: 10px;
        grid-auto-flow: column;
        grid-template-columns: auto 1fr auto auto;
        &.filters-expanded {
            min-width: 425px; // sanity width
            @media (max-width: $contact-menu-breakpoint) {
                grid-row-gap: 5px;
                grid-template-rows: $fr-1 $fr-1;
                grid-template-columns: 1fr auto;
                > .selected-count {
                    grid-column: 1;
                    grid-row: 2;
                }
                > .search-bar {
                    width: 100%;
                    grid-column: 1 / span 2;
                }
                > div.menu-buttons {
                    width: 100%;
                    grid-column: 2 / span 2;
                    grid-row: 2;
                }
            }
            > div.menu-buttons .show-small {
                @media (min-width: $contact-menu-breakpoint) {
                    display: none;
                }
            }
        }
        &.filters-collapsed {
            $breakpoint: 810px; // calculation wraps early - slightly more than $contact-menu-breakpoint - $sidebar-width + $sidebar-collapse-width
            min-width: 510px; // sanity width
            @media (max-width: $breakpoint) {
                grid-row-gap: 5px;
                grid-template-rows: $fr-1 $fr-1;
                grid-template-columns: 1fr auto auto;
                > .selected-count {
                    grid-column: 1;
                    grid-row: 2;
                }
                > .search-bar {
                    width: 100%;
                    grid-column: 1 / span 2;
                }
                > div.menu-buttons {
                    width: 100%;
                    grid-column: 2 / span 2;
                    grid-row: 2;
                }
            }
            > div.menu-buttons .show-small {
                @media (min-width: $breakpoint) {
                    display: none;
                }
            }
        }
        > .search-bar {
            width: $search-bar-width;
            grid-column: auto;
            .input-group-addon {
                padding-right: 0;
                background-color: $color-white;
            }
            input {
                border-left-width: 0;
            }
        }
        > div.menu-buttons {
            display: grid;
            grid-template-columns: 1fr auto;
        }
    }
    .pagination-and-starred {
        display: grid;
        align-items: center;
        padding: 5px 15px;
        border-bottom: solid 1px color-gray(90);
        grid-column: 1;
        grid-row: 2;
        grid-template-columns: auto 24px;
        grid-column-gap: 15px;
        > pagination-sub-menu {
            padding: 0;
            border-bottom: 0;
            grid-column: 1;
        }
        > .star {
            font-size: $font-size-md;
            grid-column: 2;
        }
    }
    > div.content {
        overflow: auto;
        grid-column: 1;
        grid-row: 3;
        .list-group-item {
            padding: 0;
        }
    }
}
