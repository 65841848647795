financial-accounts-report {
    display: grid;
    height: $full-container-height;
    grid-template-rows: auto $fr-1;
    .loading {
        padding: $container-padding;
    }
    .periods {
        display: grid;
        overflow: auto;
        grid-auto-flow: column;
        .title {
            text-align: left;
            .name {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
        .credits {
            background-color: $color-green-light;
        }
        .debits {
            background-color: $color-red-light;
        }
        .date {
            font-size: $font-size-ml;
            overflow: hidden;
        }
        .balance {
            background: color-gray(90);
            font-weight: bold;
        }
    }
    .period {
        padding-bottom: $beacon-padding;
        text-align: right;
        > div {
            height: 40px;
            border-bottom: 1px solid $color-border;
            line-height: 40px;
            &.date {
                height: 50px;
                line-height: 50px;
            }
        }
        &:first-child {
            > div {
                padding-left: $container-padding;
            }
        }
        &:last-child {
            > div {
                padding-right: $container-padding;
            }
        }
    }
    a:hover {
        text-decoration: none;
    }
}
