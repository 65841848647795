contact-notices {
    .notice-container {
        display: grid;
        align-items: center;
        width: 100%;
        transition-delay: 0.2s;
        border-bottom: 1px solid lighten($color-warning-border, 5%);
        background-color: $color-warning;
        grid-column-gap: $container-padding;
        grid-template-columns: auto 1fr auto;
        > div {
            text-align: right;
            &.notice-title {
                margin: 19px 15px;
                text-align: left;
            }
            &.notice-close {
                display: table;
                width: 70px;
                height: 100%;
                border-left: 1px $color-warning-border solid;
                font-size: $font-size-lg;
                text-align: center;
                i {
                    display: table-cell;
                    transition: color 0.2s;
                    color: color-gray(60);
                    cursor: pointer;
                    vertical-align: middle;
                    &:hover {
                        color: color-gray(35);
                    }
                }
            }
        }
    }
}
