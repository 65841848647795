contacts-list-item {
    display: block;
    transition: background-color 0.2s;
    border-bottom: 1px solid $color-bg;
    font-size: $font-size-sm;
    user-select: none;
    .contact-row {
        display: grid;
        padding: $container-padding;
        font-size: $font-size-sm;
        cursor: pointer;
        grid-template-rows: $fr-1 auto;
        grid-template-columns: 25px 2fr 1fr 1fr 80px 24px;
        grid-column-gap: 15px;
        avatar {
            grid-column: 1;
            .avatar {
                margin-top: -4px;
            }
            .avatar,
            .avatar img {
                width: 25px;
                height: 25px;
            }
        }
        .contact-details {
            min-width: 0;
            grid-column: 2;
            .main-details {
                h4 {
                    margin-top: 0;
                    margin-bottom: 0;
                    font-size: $font-size-md;
                    font-weight: 600;
                    a,
                    a:visited {
                        color: $color-blue;
                    }
                }
                address {
                    width: 100%;
                    max-height: 18px;
                    font-weight: 300;
                    text-align: left;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
                .people {
                    margin: 0;
                    .people-single-contact {
                        display: block;
                        height: 18px;
                        line-height: 1.25em;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        a,
                        span {
                            margin-right: 10px;
                        }
                    }
                }
            }
            .more-details {
                line-height: 1.35em;
            }
            .address > address {
                margin-bottom: 0;
            }
        }
        .contact-details-2 {
            grid-column: 3;
        }
        .contact-details-3 {
            grid-column: 4;
            .fa-circle {
                margin-right: 5px;
            }
        }
        .task-actions {
            grid-column: 5;
            place-self: center;
        }
        .star {
            padding: 5px 0;
            font-size: $font-size-md;
            line-height: 25px;
            grid-column: 6;
            place-self: center;
        }
        div.tags-row {
            display: flex;
            grid-column: 2 / span 4;
            grid-row: 2;
            .tags {
                flex-grow: 1;
                margin-top: 10px;
                line-height: 16px;
                .tag {
                    max-width: 150px;
                    margin: 0 3px 0 0;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
            .show-more {
                width: 250px;
                margin-top: 10px;
                font-size: $font-size-xs;
                line-height: 27px;
                text-align: right;
                white-space: nowrap;
            }
        }
        &:hover {
            div.hover-buttons {
                @extend .fade-in, .ng-enter;
                opacity: 1;
            }
            div.select-box {
                transition: border-color 0.2s ease-out;
                border-color: $color-primary;
            }
        }
    }
    &:hover {
        background-color: $color-list-item-hover;
    }
}
.selected {
    contacts-list-item {
        background-color: $color-list-item-active;
        &:hover {
            background-color: $color-list-item-active-hover;
        }
        .select-box {
            background-color: $color-blue;
        }
    }
}
