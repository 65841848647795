@use 'sass:math';
preferences-integration {
    .panel-heading {
        .state {
            margin-right: 10px;
        }
        .buttons {
            display: grid;
            grid-gap: 5px;
            grid-auto-flow: column;
        }
    }
    .integration-subhead {
        margin-top: 0;
        font-weight: 600;
    }
    ol {
        padding-left: math.div($gutter, 2);
    }
    .network_logo {
        img {
            max-width: 200px;
            max-height: 100px;
        }
    }
    .well {
        border-radius: $border-radius;
        .btn-link {
            margin-right: 20px;
            padding: 0;
            font-size: $font-size-md;
            &:last-of-type {
                margin-right: 0;
            }
            &.disabled {
                color: $color-text;
                opacity: 1;
            }
        }
        p:not(:first-child) {
            margin-top: 10px;
        }
    }
}
