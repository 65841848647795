.card {
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 1px solid $color-border;
    border-radius: 16px;
    background: $color-white;
    box-shadow:
        0 2px 1px -1px $color-black-alpha-20,
        0 1px 3px 0 $color-black-alpha-10;
    .card-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 20px 25px;
    }
    .card-heading {
        display: flex;
        align-items: center;
        height: 70px;
        padding: 0 25px;
        border-bottom: 1px solid $color-border;
        .card-title {
            flex-grow: 1;
            margin: 0;
            font-weight: bolder;
        }
        .card-actions {
            .card-action {
                text-transform: uppercase;
                > i {
                    margin-right: 5px;
                }
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
    .card-footer {
        display: flex;
        & > * {
            flex: 1;
            padding: 10px $container-padding;
            &:not(:last-child) {
                border-right: 1px solid $color-border;
            }
            transition: color 0.25s ease-out;
            &:hover {
                text-decoration: none;
            }
        }
        border-top: 1px solid $color-border;
        font-size: $font-size-sm;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
    }
}
