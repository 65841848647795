$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
$fa-font-path: '~@fortawesome/fontawesome-pro/webfonts';

// Fonts
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700');
@import '~bootstrap-sass/assets/stylesheets/bootstrap';
@import '~@fortawesome/fontawesome-pro/scss/fontawesome';
@import '~@fortawesome/fontawesome-pro/scss/solid';
@import '~@fortawesome/fontawesome-pro/scss/regular';
@import '~@fortawesome/fontawesome-pro/scss/light';
@import '~@fortawesome/fontawesome-pro/scss/brands';
@import '~@fortawesome/fontawesome-pro/scss/duotone';

// Dependencies
@import '~angularjs-slider/dist/rzslider';
@import '~angular-hotkeys/build/hotkeys';
