search {
    .animate {
        transition: all 0.25s ease-out;
        &.ng-enter {
            .modal-backdrop {
                opacity: 0;
            }
            .search {
                top: 25px;
                opacity: 0;
            }
            &.ng-enter-active {
                .search {
                    top: 50px;
                    opacity: 1;
                }
                .modal-backdrop {
                    opacity: 1;
                }
            }
        }
        &.ng-leave {
            .search {
                top: 50px;
                opacity: 1;
            }
            .modal-backdrop {
                opacity: 1;
            }
            &.ng-leave-active {
                .search {
                    top: 25px;
                    opacity: 0;
                }
                .modal-backdrop {
                    opacity: 0;
                }
            }
        }
    }
    .modal-backdrop {
        transition: all 0.25s ease-out;
    }
    .search {
        position: fixed;
        top: 50px;
        right: 100px;
        left: 100px;
        transition: all 0.25s ease-out;
        .input-group {
            &.input-group-expanded {
                .input-group-addon:first-child {
                    border-bottom-left-radius: 0;
                }
                .input-group-addon:last-child {
                    border-bottom-right-radius: 0;
                }
            }
            .input-group-addon {
                border: 0;
                background-color: $color-white;
                font-size: $font-size-lg;
            }
            .input-close {
                width: 72px;
                padding: 0 20px;
                transition: color 0.2s;
                color: color-gray(90);
                font-size: 25px;
                text-align: center;
                cursor: pointer;
                &:hover {
                    color: color-gray(35);
                }
            }
            .form-control {
                height: 50px;
                border: 0;
            }
        }
        .results {
            border-top: 1px $color-border solid;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            background-color: $color-white;
            > * {
                display: block;
                padding: 10px 15px;
                border-bottom: 1px $color-border solid;
                &:last-child {
                    border-bottom: 0;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }
            a {
                display: grid;
                transition: background-color 0.2s ease-out;
                grid-template-columns: 20px 1fr;
                grid-gap: 10px;
                > .icon {
                    text-align: center;
                }
                &:hover,
                &:focus {
                    background-color: color-gray(95);
                    text-decoration: none;
                }
                &.active {
                    background: $color-primary;
                    color: $color-white;
                    .text-muted {
                        color: $color-white;
                    }
                }
            }
        }
        .tip {
            margin: 10px 0;
            color: $color-white;
            font-size: $font-size-xs;
            text-align: right;
            text-shadow: 1px 1px 3px $color-black;
            .shortcut {
                display: inline-block;
            }
            kbd {
                padding: 4px 6px;
                border: 1px $color-border solid;
                background: $color-white;
                color: $color-text;
                font-size: $font-size-xs;
                text-shadow: none;
            }
        }
    }
    @media (max-width: $screen-sm-max) {
        .search {
            right: 15px;
            left: 15px;
        }
    }
}
