fix-send-newsletter-item {
    .panel .panel-body {
        contact-person,
        contact-address {
            h3 {
                font-size: 18px;
            }
            &:last-child {
                .wrapper {
                    padding-bottom: 0;
                    border-bottom: 0;
                }
            }
            .actions {
                display: none;
            }
        }
    }
}
