// Padding spacer utilities
@for $i from 1 through length($spacers) {
    $size: #{nth($spacers, $i)};
    $scale: #{$i - 1};
    // Set a #{$size} padding to all sides
    .p-#{$scale} {
        padding: #{$size} !important;
    }
    // Set a #{$size} padding to the top
    .pt-#{$scale} {
        padding-top: #{$size} !important;
    }
    // Set a #{$size} padding to the right
    .pr-#{$scale} {
        padding-right: #{$size} !important;
    }
    // Set a #{$size} padding to the bottom
    .pb-#{$scale} {
        padding-bottom: #{$size} !important;
    }
    // Set a #{$size} padding to the left
    .pl-#{$scale} {
        padding-left: #{$size} !important;
    }
    // Set a #{$size} padding to the left & right
    .px-#{$scale} {
        padding-right: #{$size} !important;
        padding-left: #{$size} !important;
    }
    // Set a #{$size} padding to the top & bottom
    .py-#{$scale} {
        padding-top: #{$size} !important;
        padding-bottom: #{$size} !important;
    }
}
// Responsive padding spacer utilities
// Loop through the breakpoint values
@each $breakpoint in map-keys($breakpoints) {
    // Loop through the spacer values
    @for $i from 1 through length($spacers) {
        @include respond-above($breakpoint) {
            $size: #{nth($spacers, $i)}; // xs, sm, md, lg, xl
            $scale: #{$i - 1}; // 0, 1, 2, 3, 4, 5, 6
            // Set a #{$size} padding to all sides at the #{$breakpoint} breakpoint
            .p-#{$breakpoint}-#{$scale} {
                padding: #{$size} !important;
            }
            // Set a #{$size} padding to the top at the #{$breakpoint} breakpoint
            .pt-#{$breakpoint}-#{$scale} {
                padding-top: #{$size} !important;
            }
            // Set a #{$size} padding to the right at the #{$breakpoint} breakpoint
            .pr-#{$breakpoint}-#{$scale} {
                padding-right: #{$size} !important;
            }
            // Set a #{$size} padding to the bottom at the #{$breakpoint} breakpoint
            .pb-#{$breakpoint}-#{$scale} {
                padding-bottom: #{$size} !important;
            }
            // Set a #{$size} padding to the left at the #{$breakpoint} breakpoint
            .pl-#{$breakpoint}-#{$scale} {
                padding-left: #{$size} !important;
            }
            // Set a #{$size} padding to the left & right at the #{$breakpoint} breakpoint
            .px-#{$breakpoint}-#{$scale} {
                padding-right: #{$size} !important;
                padding-left: #{$size} !important;
            }
            // Set a #{$size} padding to the top & bottom at the #{$breakpoint} breakpoint
            .py-#{$breakpoint}-#{$scale} {
                padding-top: #{$size} !important;
                padding-bottom: #{$size} !important;
            }
        }
    }
}
// responsive padding for containers
.p-responsive {
    padding-right: $spacer-3 !important;
    padding-left: $spacer-3 !important;
    @include respond-above(sm) {
        padding-right: $spacer-6 !important;
        padding-left: $spacer-6 !important;
    }
    @include respond-above(lg) {
        padding-right: $spacer-3 !important;
        padding-left: $spacer-3 !important;
    }
}
.chart-container {
    max-width: 1024px;
    margin: 0 auto;
}
