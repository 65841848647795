@import 'modal/modal';
collection-selector {
    > button.btn {
        display: flex;
        width: 100%;
        text-align: center;
        div:first-child {
            flex-grow: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        div:nth-child(2n) {
            margin-left: 10px;
        }
    }
}
