// Breakpoints
// Breakpoint widths
$width-xs: 0;
$width-sm: 768px;
$width-md: 992px;
$width-lg: 1200px;
$width-xl: 3840px;
$breakpoints: (
    sm: $width-sm,
    md: $width-md,
    lg: $width-lg,
    xl: $width-xl,
) !default;
@mixin respond-above($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        $breakpoint-value: map-get($breakpoints, $breakpoint);
        @media (min-width: $breakpoint-value) {
            @content;
        }
    }
}
@mixin respond-below($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        $breakpoint-value: map-get($breakpoints, $breakpoint);
        @media (max-width: $breakpoint-value) {
            @content;
        }
    }
}
