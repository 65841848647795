home-weekly-activity {
    .table {
        font-size: $font-size-sm;
        th:first-child {
            padding-left: 25px;
        }
        th:last-child,
        td:last-child {
            padding-right: 25px;
        }
    }
}
