$contact-viewport-width: calc(100vw - #{$sidebar-width});
@import 'bulkActions/bulkActions';
@import 'drawer/drawer';
@import 'flows/flows';
@import 'list/list';
@import 'multiple/multiple';
@import 'show/show';
@import 'sidebar/sidebar';
contacts {
    display: grid;
    height: 100%;
    overflow: hidden;
    grid-template-columns: auto 1fr;
    > contacts-sidebar {
        grid-column: 1;
    }
    > ui-view {
        width: 100%;
        overflow: hidden;
        grid-column: 2;
    }
}
