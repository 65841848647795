import-csv-preview {
    .table > thead > tr > th,
    .table > tbody > tr > td {
        padding: 8px 10px;
        white-space: nowrap;
        &:first-child {
            padding-left: $container-padding;
        }
        &:last-child {
            padding-right: $container-padding;
        }
    }
    .table > thead > tr.categories > th {
        padding-top: 5px;
        padding-bottom: 5px;
        border-right: 1px solid $color-list-item-active-hover;
        border-bottom: 0;
        background-color: $color-list-item-hover;
        font-size: 80%;
        &:last-child {
            border-right: 0;
        }
    }
    .separator {
        border-right: 1px solid $color-border;
    }
}
