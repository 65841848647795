@use 'sass:math';

// color base
$color-black: #000;
$color-blue: #317494;
$color-blue-lighter: #e8f3fd;
$color-blue-light: #428bca;
$color-blue-deep: #52a8ec;
$color-cyan-dark: #3eb1c8;
$color-cyan-darker: #007398;
$color-gold: #f5be19;
$color-green: #5cb85c;
$color-green-dark: #56aa00;
$color-green-darker: #090;
$color-green-light: #def8cf;
$color-mustard: #ead50e;
$color-mustard-dark: #4e4001;
$color-orange-lighter: #f7e5c1;
$color-orange: #f6921e;
$color-orange-dark: #dd7d1b;
$color-orange-darker: darken($color-orange-dark, 5%);
$color-orange-light: #f9b625;
$color-red: #f00;
$color-red-light: #fcc;
$color-red-dark: #98191c;
$color-white: #fff;
$color-yellow-light: #feffde;
$color-gray-blue: #5e6b7b;
$color-gray-cyan: #909b9c;
$color-gray-pink: #666062;
$color-goal: #ffcf07;
$color-goal-light: #ffe67c;

// color grey
@function color-gray($gray-value) {
    @return lighten(#000, (round(math.div($gray-value, 5)) * 5));
}

// color custom
$color-primary: $color-blue;
$color-secondary: $color-gold;
$color-secondary-light: lighten($color-secondary, 38%);
$color-secondary-light-hover: lighten($color-secondary, 33%);
$color-text: color-gray(35);
$color-text-muted: color-gray(20);
$color-link: color-gray(35);
$color-reverse: $color-white;
$color-bg: color-gray(90);
$color-bg-dashboard: #ebecec;
$color-info: $color-blue;
$color-link: $color-info;
$color-success: $color-green;
$color-success-dark: darken($color-success, 10%);
$color-success-dark-border: lighten($color-success-dark, 10%);
$color-warning: $color-gold;
$color-warning-border: darken($color-warning, 10%);
$color-warning-dark: darken($color-warning, 10%);
$color-warning-dark-border: darken($color-warning-dark, 10%);
$color-danger: #f00;
$color-danger-dark: #a94442;
$color-danger-dark-border: lighten($color-danger, 20%);
$color-nav-selected: #4a90e2;
$color-nav-selected-dark: darken($color-nav-selected, 10%);
$color-menu-bg: color-gray(95);
$color-menu-border: lighten(color-gray(35), 50%);
$color-blue-border: lighten($color-blue, 10%);
$color-panel-bg: color-gray(35);
$color-panel-border: color-gray(75);
$color-merge-selected: #bbcd5c;
$color-merge-ignored: #e55b36;
$color-merge-default: #dbdbdb;
$color-pledges: $color-orange-light;
$color-monthly-goal: $color-cyan-dark;
$color-monthly-average: $color-gray-pink;
$color-filter-bar: #4a535e;
$color-filter-bar-dark: darken($color-filter-bar, 5%);
$color-filter-select-bg: #2f363f;
$color-filter-reverse: #e45c5c;
$color-filter-reverse-dark: #9b0000;
$color-sidebar-bg: #606b79;
$color-border: color-gray(90);
$color-progress: $color-gold;
$color-progress-dark: $color-orange-dark;
$color-list-menu-active: #4a90e2;
$color-list-item-hover: lighten($color-blue, 55%);
$color-list-item-active: lighten($color-blue, 50%);
$color-list-item-active-hover: lighten($color-blue, 45%);
$color-tag: $color-blue;
$color-tag-light: lighten($color-tag, 5%);
$color-tag-reverse: $color-filter-reverse-dark;
$color-tag-reverse-light: lighten($color-tag-reverse, 5%);

// color alpha
$color-black-alpha-50: rgba($color-black, 0.5);
$color-black-alpha-20: rgba($color-black, 0.2);
$color-black-alpha-10: rgba($color-black, 0.1);
$color-blue-deep-alpha-80: rgba($color-blue-deep, 0.8);
$color-blue-deep-alpha-60: rgba($color-blue-deep, 0.6);
$color-blue-deep-alpha-10: rgba($color-blue-deep, 0.1);
$color-secondary-alpha-50: rgba($color-secondary, 0.5);
$color-secondary-alpha-20: rgba($color-secondary, 0.2);

$gutter: 30px;
$border-radius: 3px;

// width
$sidebar-width: 250px;
$sidebar-collapse-width: 20px;

// height
$height-progress-bar: 24px;
$height-nav: 93px;
$height-secondary: 43px;
$height-setup: -40px;
$height-secondary-nav: $height-nav + $height-secondary;
$height-setup-nav: $height-nav + $height-setup;
$height-footer: 30px;
$height-comment-body: 15px;
$height-comment-footer: 13px;

$nav-padding: 20px;
$beacon-padding: 40px; //partial height
$container-padding: 15px;
$container-padding-left: $container-padding;
$container-padding-right: $container-padding;
$content-padding-left: 5px;
$content-padding-right: 10px;

$contact-menu-breakpoint: 1000px;
$menu-breakpoint: 800px;
$full-container-height: calc(100vh - 86px); //workaround for safari jumping containers

// grid
$fr-1: minmax(1px, 1fr);
