contacts-flows-setup {
    display: grid;
    grid-template-rows: $flow-menu-height $fr-1;
    .menu {
        display: grid;
        align-items: center;
        height: $flow-menu-height;
        padding: 0 $container-padding;
        border-bottom: 1px solid $color-border;
        grid-auto-flow: column;
        .input-group-addon {
            padding-right: 0;
            background-color: $color-white;
        }
        input[type='text'] {
            border-left: 0;
        }
    }
    .columns {
        display: grid;
        height: $flow-container-height;
        padding: 10px $container-padding;
        overflow: auto;
        grid-auto-flow: column;
        grid-auto-columns: minmax($flow-column-min-width, $flow-column-max-width);
        grid-gap: 10px;
        > .as-sortable-placeholder {
            border: 2.5px dashed $color-blue;
            background-color: $color-blue-deep-alpha-60;
        }
        .column {
            display: grid;
            min-width: 240px;
            max-width: 380px;
            border: 1px solid $color-border;
            background-color: color-gray(95);
            overflow: hidden;
            grid-template-rows: 44px 40px $fr-1;
        }
        .heading {
            display: grid;
            align-items: center;
            height: 44px;
            padding: 0 $container-padding;
            transition: border-bottom-color 0.2s ease-out;
            border-bottom: 5px solid $color-border;
            background-color: $color-white;
            font-size: $font-size-md;
            grid-template-columns: auto 1fr auto;
            grid-gap: 10px;
            input {
                width: 100%;
                border: 0;
                border-bottom: 1px solid $color-border;
                font-weight: bold;
                &:focus {
                    outline: 0;
                }
            }
            &.color-text {
                border-bottom-color: $color-text;
            }
            &.color-warning {
                border-bottom-color: $color-warning;
            }
            &.color-danger {
                border-bottom-color: $color-danger;
            }
            &.color-success {
                border-bottom-color: $color-success;
            }
            &.color-info {
                border-bottom-color: $color-info;
            }
            &.color-border {
                border-bottom-color: $color-border;
            }
        }
        .colors {
            display: grid;
            align-items: center;
            justify-content: center;
            height: 40px;
            border-bottom: 1px solid $color-border;
            background: $color-white;
            grid-auto-flow: column;
            grid-auto-columns: auto;
            .color {
                width: 15px;
                height: 15px;
                margin: 2.5px 12.5px;
                transition: all 0.2s ease-out;
                border-radius: 100%;
                cursor: pointer;
                &.color-text {
                    background-color: $color-text;
                }
                &.color-warning {
                    background-color: $color-warning;
                }
                &.color-danger {
                    background-color: $color-danger;
                }
                &.color-success {
                    background-color: $color-success;
                }
                &.color-info {
                    background-color: $color-info;
                }
                &.color-border {
                    background-color: $color-border;
                }
                &.active {
                    width: 20px;
                    height: 20px;
                    margin: 0 10px;
                }
            }
        }
        .statuses {
            overflow: auto;
            > .as-sortable-placeholder {
                border: 2.5px dashed $color-blue;
                background-color: $color-blue-deep-alpha-60;
            }
        }
        .status > div {
            padding: 10px;
            transition: background-color 0.2s ease-out;
            border-bottom: 1px solid $color-border;
            background-color: $color-white;
            &:hover {
                background-color: $color-list-item-hover;
            }
        }
    }
}
.columns .as-sortable-drag {
    position: fixed;
    box-shadow: 0 3px 9px $color-black-alpha-50;
    > .status > div {
        border: 0;
        background-color: $color-list-item-hover;
    }
    &.columns {
        padding: 0;
    }
}
