task-list-contact {
    $avatar-size: 26px;
    display: grid;
    & + & {
        //all except first
        border-top: 1px solid $color-bg;
    }
    .heading {
        display: grid;
        align-items: end;
        margin-top: 2px;
        font-weight: bold;
        grid-column-gap: 10px;
        grid-template-columns: $avatar-size 1fr;
        avatar {
            grid-column: 1;
            &,
            .avatar,
            .avatar img {
                width: $avatar-size;
                height: $avatar-size;
            }
        }
    }
    .details {
        display: flex;
        flex-direction: column;
        label {
            @extend .h4;
            display: block;
            margin: 0;
            color: color-gray(60);
            font-size: $font-size-xs;
        }
        .address {
            address {
                margin-bottom: 10px;
                font-size: $font-size-sm;
                font-weight: bold;
                .street {
                    white-space: pre;
                }
            }
        }
        .person,
        .status,
        .commitment {
            display: grid;
            margin-bottom: 10px;
            grid-template-columns: repeat(3, 1fr);
            > div {
                a,
                span {
                    font-size: $font-size-sm;
                    font-weight: bold;
                    white-space: nowrap;
                }
            }
        }
        .tags {
            margin-top: 3px;
        }
    }
}
