preferences-sidebar {
    @extend .sidebar-expandable;
    > .content {
        @extend .sidebar;
        padding: 0;
        .selector {
            margin-top: 20px;
            .account-list-dropdown {
                margin-top: 5px;
            }
        }
        .nav.nav-pills li.has-children a > i {
            transition:
                transform 0.2s ease-out,
                opacity 0.2s ease-out;
        }
        .nav.nav-pills li ul.nav-children {
            padding: 0;
            border-bottom: 1px solid $color-gray-blue;
            background-color: $color-filter-bar-dark;
            list-style: none;
            > li {
                > a {
                    border: 0;
                    font-size: $font-size-sm;
                    i {
                        opacity: 0.2;
                    }
                    &:hover {
                        background-color: darken($color-filter-bar-dark, 10%);
                        text-decoration: none;
                        i {
                            opacity: 1;
                        }
                    }
                }
                &.active a {
                    background-color: darken($color-filter-bar-dark, 10%);
                    i {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
