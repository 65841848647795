$flow-menu-height: 65px;
$appeal-summary-height: 71px;
$flow-column-min-width: 240px;
$flow-column-max-width: 380px;
// workaround for safari jumping containers
$flow-container-height: calc(#{$full-container-height} - #{$flow-menu-height} - #{$appeal-summary-height});
@import 'column/column';
@import 'contact/contact';
@import 'updateDonations/updateDonations';
appeals-flows {
    display: grid;
    grid-template-columns: auto 1fr;
    .flows {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: $flow-menu-height $appeal-summary-height 1fr;
        .menu {
            display: grid;
            align-items: center;
            height: $flow-menu-height;
            padding: 0 $container-padding;
            transition: background-color 0.2s;
            border-bottom: 1px solid $color-border;
            grid-template-columns: auto 1fr auto 275px;
            grid-gap: 10px;
            .selected-count {
                color: $color-white;
                font-size: 0.9rem;
                line-height: 33px;
                white-space: nowrap;
            }
            .input-group-addon {
                padding-right: 0;
                background-color: $color-white;
            }
            input[type='text'] {
                border-left: 0;
            }
            &.selected {
                background-color: $color-list-menu-active;
            }
        }
        .flow-columns {
            display: grid;
            height: $flow-container-height;
            padding: 10px $container-padding;
            overflow: auto;
            grid-auto-flow: column;
            grid-auto-columns: minmax($flow-column-min-width, $flow-column-max-width);
            grid-gap: 10px;
            grid-row: 3;
        }
        div[ui-view='drawer'] {
            grid-row: 1 / span 3;
            grid-column: 2;
        }
    }
}
