preferences-organizations-account-lists {
    display: grid;
    height: $full-container-height;
    margin: -10px -15px -60px;
    grid-template-rows: auto $fr-1;
    > .menu {
        display: grid;
        align-items: center;
        height: 65px;
        padding: 0 $container-padding;
        transition: background-color 0.2s ease-out;
        border-bottom: 1px solid $color-border;
        grid-template-columns: 1fr auto auto;
        grid-column-gap: 10px;
        .search {
            width: 275px;
            .input-group-addon {
                padding-right: 0;
                background: $color-white;
            }
            .search-filter {
                border-left: 0;
            }
        }
        &.selected {
            background-color: $color-nav-selected;
            .section {
                > a.menu-item {
                    transition: color 0.2s ease-out;
                    color: $color-white;
                    &:hover {
                        background-color: $color-nav-selected-dark;
                    }
                }
                &.active {
                    > a.menu-item {
                        background-color: $color-nav-selected-dark;
                    }
                }
            }
        }
        .section {
            &-field {
                height: 35px;
                color: $color-white;
                font-size: $font-size-sm;
                .chosen-choices {
                    width: 250px;
                    max-height: 34px;
                    transition: max-height 0.2s;
                    overflow-y: auto;
                }
                .chosen-container-active {
                    .chosen-choices {
                        max-height: 250px;
                    }
                }
            }
            &-selector {
                padding: $container-padding 10px;
                color: $color-white;
                text-align: right;
                > a {
                    color: $color-white;
                }
            }
        }
    }
    .account-lists {
        padding-bottom: $beacon-padding;
        overflow: auto;
    }
    .blankslate {
        margin: $container-padding;
    }
    .account-list {
        display: grid;
        margin-top: 10px;
        border-bottom: 1px solid $color-border;
        grid-template-columns: repeat(3, 1fr);
        .name {
            margin: 0;
            padding: 10px $container-padding 5px;
            grid-column: 1 / span 3;
        }
        h4 {
            margin: 0;
            padding: 0 $container-padding 10px;
        }
        .box {
            padding: 10px $container-padding;
            border-bottom: 1px solid $color-border;
            &.empty {
                display: grid;
                align-items: center;
                height: 100%;
                min-height: 65px;
                text-align: center;
            }
            &:last-child {
                border-bottom: 0;
            }
        }
        .user {
            display: grid;
            align-items: center;
            grid-template-columns: 1fr 39px;
            > div:last-child {
                text-align: center;
            }
        }
        > div {
            border-top: 1px solid $color-border;
            border-right: 1px solid $color-border;
            &:last-child {
                border-right: 0;
            }
        }
    }
}
