@use 'sass:math';
@import 'preferences/preferences';
setup {
    display: grid;
    width: 100vw;
    height: calc(100vh - #{$height-footer});
    grid-template-rows: auto $fr-1;
    > menu {
        border-bottom: 1px solid $color-border;
        grid-row: 1;
    }
    > div.root {
        overflow: hidden;
        grid-row: 2;
        > ui-view {
            display: block;
            height: 100%;
        }
    }
    setup-start,
    setup-connect,
    setup-account,
    setup-finish {
        display: block;
        height: 100%;
        .panel {
            border: 0;
        }
        .panel-heading {
            margin-bottom: 20px;
            padding: 35px 20px 40px 100px;
            border-color: color-gray(75);
            background: url('images/setup/icon_alert_2x.png');
            background-repeat: no-repeat;
            background-position: $gutter center;
            background-size: auto 55px;
            font-size: $font-size-xl;
            font-weight: 600;
            line-height: 44px;
            text-align: center;
        }
        .panel-body {
            margin-right: $gutter;
            margin-left: $gutter;
            padding: 0 0 30px;
            > h2,
            > div > h2 {
                margin-top: 0;
            }
            p {
                margin-bottom: $font-size-lg;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .list-unstyled {
                display: grid;
                margin-bottom: 0;
                border: color-gray(75) 1px solid;
                border-radius: 0;
                li {
                    display: grid;
                    align-items: center;
                    padding: math.div($gutter, 2);
                    border-bottom: color-gray(75) 1px solid;
                    color: $color-link;
                    font-size: $font-size-lg;
                    text-align: left;
                    grid-template-columns: 1fr auto;
                    &:last-child {
                        border-bottom: 0;
                    }
                    .btn {
                        padding: 0 10px;
                    }
                }
            }
            label {
                margin: 5px 0 0;
                font-size: $font-size-md;
                font-weight: normal;
            }
            a {
                cursor: pointer;
            }
        }
        .panel--language {
            width: 300px;
            margin: 5px auto 0;
        }
        .panel__connect-links {
            margin-top: $font-size-lg;
        }
        .btn {
            padding: 20px;
            font-size: $font-size-lg;
        }
    }
    cover {
        .cover {
            top: 60px;
        }
        .cover-item {
            margin: 50px auto;
        }
    }
}
