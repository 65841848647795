$font-primary: 'source sans pro', sans-serif;
$font-size-md: 1rem;
$font-size-xl: $font-size-md * 2.25; // 36px
$font-size-lg: $font-size-md * 1.625; // 26px
$font-size-ml: $font-size-md * 1.25; // 20px
$font-size-sm: $font-size-md * 0.9; // 14.4px
$font-size-xs: $font-size-md * 0.75; // 12px
html,
body {
    color: $color-text;
    font-family: $font-primary;
    font-size: $font-size-md;
    overflow: hidden; // needed for safari
    a {
        cursor: pointer;
    }
}
