appointments {
    display: block;
    .panel-body {
        table {
            width: 100%;
            th:not(:first-child),
            td:not(:first-child) {
                width: 10%;
                text-align: right;
            }
        }
    }
}
