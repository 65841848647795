ul.nav.nav-tabs {
    display: grid;
    margin-bottom: 0;
    border-bottom: 0;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    &::before,
    &::after {
        display: none;
    }
    > li {
        display: block;
        > a {
            margin-right: 0;
            padding: 10px $container-padding;
            border: 0;
            border-bottom: 2px solid $color-border;
            color: $color-text;
            text-align: center;
            text-transform: uppercase;
        }
        &.active > a {
            border-bottom-color: $color-primary;
            background-color: transparent;
            color: $color-primary;
            &:hover,
            &:focus {
                border: 0;
                border-bottom: 2px solid $color-primary;
                background-color: transparent;
            }
        }
    }
}
