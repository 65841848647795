outstanding-special-needs {
    .contact {
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        &.header {
            margin-bottom: 5px;
            border-bottom: 1px solid $color-border;
            font-weight: 600;
        }
    }
}
