appeals-wizard {
    .form-group > label > span {
        white-space: nowrap;
    }
    .table {
        > tbody {
            > tr {
                > td {
                    padding-bottom: 0;
                    vertical-align: middle;
                }
            }
            tr {
                td,
                th {
                    border: 0;
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
    }
}
