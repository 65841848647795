.sidebar-expandable {
    display: grid;
    height: $full-container-height;
    background-color: $color-filter-bar;
    grid-template-columns: $sidebar-collapse-width auto;
    grid-template-rows: $fr-1;
    > .filter-collapse {
        border-right: 1px solid $color-gray-blue;
        color: $color-white;
        cursor: pointer;
        grid-column: 1;
        i {
            position: relative;
            top: 50%;
            margin-left: 3px;
            transition: transform 0.2s ease-out;
        }
    }
    > .content {
        width: $sidebar-width;
        padding: $container-padding 0;
        opacity: 1;
        overflow-y: auto;
        grid-column: 2;
        &.ng-hide {
            width: 0;
            opacity: 0;
        }
        &.ng-hide-add {
            transition:
                opacity 0.2s ease-out,
                width 0.5s ease-out 0.4s;
        }
        &.ng-hide-remove {
            transition:
                width 0.5s ease-out,
                opacity 0.2s ease-out 0.7s;
        }
    }
}
