@import 'donorAccount/donorAccount';
@import 'recommendation/recommendation';
contact-details {
    display: block;
    width: 100%;
    height: 100%;
    h3 {
        margin-top: 0;
    }
    h5 {
        margin-top: 30px;
        margin-bottom: 5px;
        font-size: $font-size-md;
        font-weight: 600;
        &:first-child {
            margin-top: 0;
        }
    }
    .inline-input {
        tags-input {
            .tags {
                border: 0;
                border-bottom: 1px solid color-gray(90);
                border-radius: 0;
                .input {
                    padding-left: 0;
                    font-size: $font-size-sm;
                }
            }
            .autocomplete {
                border-radius: 0;
            }
        }
        textarea {
            padding: 0;
            border: 0;
            border-bottom: 1px solid color-gray(90);
            border-radius: 0;
            outline: 0;
            background: transparent;
            font-size: $font-size-sm;
            font-weight: 600;
            line-height: 18px;
            box-shadow: none;
            &:focus {
                border-bottom: 1px solid $color-cyan-darker;
            }
        }
    }
    label {
        @extend h4;
        margin-bottom: 3px;
        color: color-gray(60);
        font-size: $font-size-xs;
    }
    .static-field {
        display: flex;
        align-items: center;
        font-size: $font-size-sm;
        font-weight: 600;
        line-height: 21px;
        .content {
            flex-grow: 1;
        }
    }
    .source {
        margin: 10px 0;
        .source-detail {
            padding: 8px 15px;
            border-radius: $border-radius;
            background-color: color-gray(95);
            color: color-gray(30);
            box-shadow: 0 0 4px 0 color-gray(80) inset;
            span {
                font-weight: 600;
            }
        }
    }
    .financial {
        .commitment {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 20px;
        }
        .donations {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 20px;
        }
    }
    .communication {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 20px;
        > div.tags {
            grid-column: 1 / span 2;
            tags-input {
                .tags {
                    padding: 1px;
                    .tag-item {
                        margin: 1px 2px 1px 0;
                    }
                    .input {
                        height: 26px;
                        font-weight: 600;
                    }
                }
            }
        }
        .referred-by {
            .collection-selector {
                display: flex;
                collection-selector {
                    flex-grow: 1;
                    button.btn {
                        padding: 0 0 5px;
                        border: 0;
                        border-bottom: 1px solid color-gray(90);
                        border-radius: 0;
                        font-weight: 600;
                        line-height: 22px;
                        text-align: left;
                    }
                }
                a {
                    margin-left: 10px;
                }
            }
        }
    }
    .other {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 20px;
    }
    .partner-accounts {
        display: flex;
        align-items: center;
        margin: 30px 0 8px;
        h5 {
            flex-grow: 1;
            margin: 0;
        }
    }
    contact-donor-account {
        .field {
            margin-bottom: 10px;
        }
    }
    .danger-zone {
        display: grid;
        align-items: center;
        margin-bottom: $beacon-padding;
        padding: 10px $container-padding;
        border: 1px solid $color-red-dark;
        grid-row-gap: 10px;
        grid-template-columns: 1fr auto;
    }
}
contact > div > .contact-drawer > .contact-drawer-content > contact-details .communication {
    > div {
        max-width: 205px;
    }
    > div.tags {
        max-width: 408px;
    }
}
