coaches-list-item {
    display: block;
    border-bottom: 1px solid $color-border;
    > .container > .contact {
        display: grid;
        padding: 20px 0;
        border-radius: 3px;
        grid-column-gap: 10px;
        grid-template-columns: 1fr auto;
        > .col-1 {
            > .title {
                font-size: 24px;
                font-weight: 600;
                line-height: 31px;
                a:hover {
                    text-decoration: none;
                }
            }
            > .sub-title {
                margin-bottom: 10px;
                font-weight: 600;
            }
            .charts {
                width: 450px;
                appeals-progressbar .progress {
                    margin-left: 0 !important; //weird alignment issue
                }
            }
        }
        > .col-2 {
            display: grid;
            grid-row-gap: 10px;
            grid-template-rows: auto $fr-1;
            justify-items: end;
            > .balance {
                font-size: 24px;
                font-weight: 600;
                line-height: 29px;
            }
            > div > .btn {
                height: 39px;
                font-size: 18px;
                font-weight: 600;
                line-height: 23px;
                &:first-child {
                    width: 147px;
                }
            }
        }
    }
    appeals-progressbar .progress {
        margin: 0;
    }
    .info {
        display: grid;
        margin: 0 6px;
        font-size: $font-size-sm;
        grid-template-columns: 1fr auto;
    }
}
