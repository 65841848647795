.announcement-modal {
    .image {
        display: block;
        margin: -15px -15px 15px;
        border-bottom: 1px $color-border solid;
        img {
            width: 100%;
        }
    }
    .content {
        text-align: center;
    }
}
