analysis-item {
    display: block;
    transition: background-color 0.2s;
    font-size: $font-size-sm;
    user-select: none;
    .contact-row {
        display: grid;
        align-items: center;
        height: 45px;
        font-size: $font-size-sm;
        cursor: pointer;
        grid-template-columns: 2fr repeat(3, 1fr) 1px repeat(3, 1fr);
        .divider,
        .hover-buttons {
            padding-right: 0;
            padding-left: 0;
        }
        .divider {
            height: 100%;
            padding: 0;
            border-left: 2px dotted $color-border;
        }
        > div {
            height: 45px;
            padding: 0 $container-padding;
            line-height: 45px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        > * {
            padding: 10px 0;
        }
        .hover-buttons {
            @extend .fade-in, .ng-leave;
            opacity: 0;
        }
        .filtered {
            transition: background-color 0.2s ease-out;
            background-color: $color-list-item-hover;
        }
        &:hover {
            div.hover-buttons {
                @extend .fade-in, .ng-enter;
                opacity: 1;
            }
            div.select-box {
                transition: border-color 0.2s ease-out;
                border-color: $color-primary;
            }
            .filtered {
                background-color: $color-list-item-active;
            }
        }
    }
    &:hover {
        background-color: $color-list-item-hover;
    }
}
.selected {
    analysis-item {
        background-color: $color-list-item-active;
        .filtered {
            background-color: $color-list-item-active-hover;
        }
        &:hover {
            background-color: $color-list-item-active-hover;
            .filtered {
                background-color: $color-list-item-active-hover;
            }
        }
        .select-box {
            background-color: $color-blue;
        }
    }
}
