@import 'analysis/analysis';
@import 'contributions/contributions';
@import 'designationAccounts/designationAccounts';
@import 'donations/donations';
@import 'financialAccounts/financialAccounts';
@import 'monthly/monthly';
@import 'sidebar/sidebar';
reports {
    > .reports {
        display: grid;
        grid-template-columns: auto $fr-1;
        > reports-sidebar {
            overflow: auto;
            grid-column: 1;
            grid-row: 1;
        }
        > ui-view {
            grid-row: 1;
            grid-column: 2;
            > contributions,
            > designation-accounts,
            > financial-accounts > ui-view > financial-accounts-list,
            > donations,
            > monthly {
                display: grid;
                height: $full-container-height;
                grid-template-rows: auto $fr-1;
                > .heading {
                    display: grid;
                    align-items: center;
                    height: 65px;
                    margin: 0;
                    padding: 10px 15px 5px !important;
                    border-bottom: 1px solid $color-border;
                    font-size: $font-size-lg;
                    grid-template-columns: 1fr minmax(0, 450px);
                    .progress {
                        margin-bottom: 0;
                    }
                }
                > .content {
                    padding: 15px $container-padding $beacon-padding;
                    overflow: auto;
                }
            }
        }
        &.no-sidebar {
            > reports-sidebar {
                display: none;
            }
            > ui-view {
                padding: 0;
                grid-column: 1 / span 2;
            }
        }
    }
    .panel-table {
        .panel-body {
            padding: 0;
            .table {
                margin-bottom: 0;
                font-size: $font-size-sm;
                tr {
                    th:first-child,
                    td:first-child {
                        padding-left: 15px;
                    }
                    th:last-child,
                    td:last-child {
                        padding-right: 15px;
                    }
                }
            }
        }
    }
}
