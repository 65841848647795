@use 'sass:math';
@import 'status/status';
bottom {
    padding: 0 $container-padding;
    border-top: 1px solid $color-menu-border;
    background: $color-menu-bg;
    .footer--nav {
        margin-bottom: 0;
        margin-left: 0;
        li {
            margin-right: -2px;
            padding: math.div($gutter, 5) math.div($gutter, 2);
            border-right: 1px solid $color-menu-border;
            font-size: $font-size-xs;
            &:first-child {
                padding-left: 0;
            }
            a {
                cursor: pointer;
            }
        }
    }
}
