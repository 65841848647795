coachee-progress {
    .info {
        display: grid;
        margin: 0 6px;
        font-size: $font-size-sm;
        grid-template-columns: 1fr auto;
    }
    .progress {
        position: relative;
        height: $height-progress-bar;
        border-radius: $height-progress-bar;
        background: color-gray(35);
        .progress-bar {
            position: absolute;
            left: 0;
            line-height: $height-progress-bar;
            box-shadow: none;
            &--light {
                background: $color-progress;
            }
            &--dark {
                background: $color-progress-dark;
            }
        }
        .progress-text {
            position: absolute;
            right: 0;
            margin-right: 6px;
            color: $color-white;
            font-size: $font-size-sm;
            line-height: $height-progress-bar;
            text-align: right;
        }
    }
}
