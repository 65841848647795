$modal-z-index: 1040;
// --- BELOW MODAL ---
side-drawer {
    z-index: $modal-z-index - 5;
}
.block-ui-container {
    z-index: $modal-z-index - 4 !important;
}
.announcement {
    z-index: $modal-z-index - 3;
}
menu {
    z-index: $modal-z-index - 2;
}
.dropdown-content {
    z-index: $modal-z-index - 2;
}
notifications .notifications-container {
    z-index: $modal-z-index - 1;
}
menu > .nav-global .navbar-quick .dropdown-menu {
    z-index: $modal-z-index - 1;
}
.horizontal-tab-sortable {
    z-index: $modal-z-index - 4;
    .as-sortable-item {
        z-index: 0;
    }
    .active-item {
        z-index: 1;
    }
    .as-sortable-drag {
        z-index: $modal-z-index - 1 !important;
    }
}
.arrow-down {
    z-index: $modal-z-index - 1;
}
// --- MODAL ---
.modal {
    z-index: $modal-z-index + 1 !important;
}
.modal-backdrop {
    z-index: $modal-z-index !important;
}
// --- ABOVE MODAL ---
search .search {
    z-index: $modal-z-index + 2;
}
//google places auto-complete
.pac-container {
    z-index: $modal-z-index + 2;
}
.modal .modal-dialog .modal-content .modal-body .empty-map {
    z-index: $modal-z-index + 2;
}
.modal.center .modal-dialog .modal-content .modal-body .empty-map {
    z-index: $modal-z-index + 2;
}
.chosen-container {
    .chosen-drop {
        z-index: $modal-z-index + 2;
    }
    .chosen-results-scroll {
        z-index: $modal-z-index + 2;
    }
}
.chosen-container-single {
    .chosen-search {
        z-index: $modal-z-index + 2;
    }
}
.toast-container {
    z-index: $modal-z-index + 3;
}
bottom {
    z-index: $modal-z-index + 3 !important;
}
// sass-lint:disable no-ids
#hs-beacon > div > iframe {
    z-index: $modal-z-index + 3 !important;
}
// sass-lint:enable no-ids
