appeal-summary {
    display: grid;
    padding: 10px $container-padding;
    border-bottom: 1px solid $color-border;
    background-color: $color-blue-deep-alpha-10;
    grid-gap: 10px;
    grid-template-columns: 1fr 450px;
    > .settings {
        display: grid;
        align-items: flex-start;
        grid-gap: 10px;
        grid-template-columns: 1fr 200px;
    }
    .inline-input.header {
        margin: 0;
    }
    label {
        @extend h4;
        display: block;
        margin: 0 0 3px;
        color: color-gray(60);
        font-size: $font-size-xs;
    }
}
