@import 'styles/styles';

// Components
@import 'blockUI/blockUI';
@import 'bottom/bottom';
@import 'menu/menu';
@import 'coaches/coaches';
@import 'common/common';
@import 'contacts/contacts';
@import 'home/home';
@import 'login/login';
@import 'preferences/preferences';
@import 'reports/reports';
@import 'root/root';
@import 'setup/setup';
@import 'tasks/tasks';
@import 'tools/tools';
@import 'unavailable/unavailable';
@import 'unsupported/unsupported';
// replace with sass variable once new style is ready
.border-secondary {
    border-color: $color-secondary;
}
.text-blue {
    color: $color-blue;
}
.text-success {
    color: $color-success;
}
.border-success {
    border-color: $color-success;
}
.strike {
    text-decoration: line-through;
}
.app {
    display: grid;
    max-width: 100vw; //use max instead of width to stop firefox scroll bug
    height: 100vh;
    grid-template-rows: $fr-1 $height-footer;
    > ui-view {
        height: calc(100vh - #{$height-footer});
        grid-row: 1;
    }
    > bottom {
        grid-row: 2;
    }
}
.BeaconFabButtonFrame {
    right: 20px !important;
    bottom: 10px !important;
}
.BeaconContainer {
    right: 20px !important;
    bottom: 90px !important;
}
@media print {
    @page {
        size: landscape;
    }
    html,
    body {
        overflow: auto;
    }
    body > .app {
        display: block;
        height: auto;
        > ui-view {
            height: auto;
        }
    }
    ui-view > root {
        display: block;
        max-width: unset;
        height: auto;
        > div.root > ui-view {
            height: auto;
        }
    }
    ui-view > reports > .reports {
        display: block;
    }
    bottom,
    reports-sidebar,
    a[href]::after,
    .hidden-print {
        display: none;
    }
}
