@import 'filter/filter';
@import 'filter/tags/tags';
@import 'list/list';
contacts-sidebar {
    @extend .sidebar-expandable;
    .contact-drawer {
        display: grid;
        grid-template-rows: auto $fr-1;
        > div.edit-filters {
            padding: 0 $container-padding 10px;
            color: $color-white;
            line-height: 29px;
            .btn-group-justified {
                display: flex;
                button.btn {
                    flex-grow: 1;
                    transition: background-color 0.2s ease-out;
                }
                button.btn:focus {
                    outline: none;
                }
                button.btn.active {
                    background-color: $color-sidebar-bg;
                }
            }
        }
        > div.list {
            grid-row: 2 / 2;
        }
    }
}
