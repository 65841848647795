modal {
    .show-all-fields-toggle {
        display: flex;
        align-items: center;
        color: $color-black-alpha-50;
        text-align: center;
        cursor: pointer;
        &::before,
        &::after {
            flex: 1;
            border-bottom: 1px solid $color-border;
            content: '';
        }
        & > * {
            padding: 1em;
            & > i {
                padding-left: 0.25em;
            }
        }
    }
}
