menu-balances {
    a {
        display: block;
        padding: 0 10px;
        color: $text-muted;
        font-size: $font-size-sm;
        line-height: 50px;
    }
    @media (max-width: $screen-lg-min) {
        a {
            display: none;
        }
    }
}
