monthly {
    .panel.panel-default {
        margin-bottom: 7.5px;
        .panel-body {
            padding: 0;
        }
    }
    .table {
        margin-bottom: 0;
    }
}
