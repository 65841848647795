$search-bar-width: $content-padding-left + $content-padding-right + $container-padding-left + $container-padding-right;
.lists {
    > .menu {
        display: grid;
        padding: 15px;
        transition: background-color 0.2s;
        border-bottom: 1px solid $color-menu-border;
        grid-template-rows: $fr-1;
        grid-template-columns: 1fr 1fr;
        @media (max-width: $menu-breakpoint) {
            grid-template-rows: $fr-1 $fr-1;
            grid-template-columns: 1fr;
        }
        > .search-bar {
            grid-row: 1;
            grid-column: 3;
            @media (max-width: $menu-breakpoint) {
                max-width: calc(100vw - #{$search-bar-width});
            }
        }
        > .menu-buttons {
            text-align: right;
            grid-row: 1;
            grid-column: 2;
            justify-items: end;
            @media (max-width: $menu-breakpoint) {
                text-align: left;
                grid-row: 2;
                grid-column: 1;
            }
        }
        &.selected {
            background-color: $color-list-menu-active;
        }
        > .selected-count {
            display: grid;
            align-items: center;
            column-gap: 10px;
            grid-auto-flow: column;
            .tip {
                display: flex;
                font-size: $font-size-sm;
                .shortcut {
                    margin-right: 5px;
                }
                kbd {
                    padding: 4px 6px;
                    border: 1px $color-border solid;
                    background: $color-white;
                    color: $color-text;
                    font-size: $font-size-xs;
                }
                &.selected {
                    color: $color-white;
                }
            }
        }
    }
    &.item-list-sub-menu {
        padding: 6px 15px;
        padding-top: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid color-gray(80);
        .loading {
            font-size: $font-size-md;
        }
    }
    &.content {
        overflow-y: auto;
        pagination > div {
            padding: 15px 0;
        }
        .blankslate {
            margin: 15px;
        }
    }
}
