weekly-report {
    .panel-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .questions {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 2em;
        row-gap: 2em;
        h5 {
            font-weight: bold;
        }
    }
}
