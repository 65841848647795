$menu-breakpoint: 900px;
@import 'accountLists/accountLists';
@import 'balances/balances';
@import 'impersonationBar/impersonationBar';
@import 'notifications/notifications';
menu {
    margin: 0;
    padding: 0;
    @keyframes slideIn {
        0% {
            transform: translateY(1rem);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
        0% {
            transform: translateY(1rem);
            opacity: 0;
        }
    }
    .navbar {
        margin-bottom: 0;
        border-top: 5px solid $color-secondary;
        border-radius: 0;
        .navbar-brand {
            padding: 10px 15px;
            img {
                height: 30px;
            }
        }

        .navbar-collapse {
            @include respond-below(sm) {
                height: calc(100vh - 90px);
                overflow-y: auto;
                padding-bottom: 50px;
            }
        }
        .navbar-nav {
            font-size: $font-size-sm;
            li > a {
                display: grid;
                align-items: center;
                height: 50px;
                padding: 15px 10px;
                transition: background-color 0.1s ease-out;
                color: color-gray(40);
                grid-auto-flow: column;
                grid-gap: 5px;
                .badge {
                    background: $color-secondary;
                    &.badge-danger {
                        background: $color-danger-dark;
                    }
                    &.badge-info {
                        background: $color-info;
                    }
                }
                &:hover {
                    background: color-gray(90);
                    color: color-gray(40);
                }
            }
        }
        .navbar-right {
            font-size: $font-size-md;
            .separator {
                height: 30px;
                margin: 10px 5px;
                border-right: 1px $color-border solid;
            }
            .notifications .dropdown-content {
                width: 300px;
                grid-template-rows: $fr-1;

                @include respond-below(sm) {
                    max-height: 350px;
                    width: 100%;
                }
            }
            .account {
                > a {
                    padding: 10px;
                    font-size: $font-size-sm;
                    .avatar img {
                        width: 30px;
                        height: 30px;
                        border-radius: 100%;
                    }
                }
                .dropdown-content {
                    width: 450px;
                    coachee-progress {
                        padding: 10px 15px;
                    }
                    .dropdown-content-menu {
                        border-top: 1px $color-border solid;
                    }
                }
            }
        }
        .dropdown-content {
            display: none;
            position: absolute;
            width: 250px;
            max-height: calc(100vh - 110px);
            border-bottom-left-radius: 0.3125rem;
            border-bottom-right-radius: 0.3125rem;
            background-color: $color-white;
            box-shadow: 0 8px 16px 0 $color-black-alpha-20;
            overflow-y: auto;
            animation-name: slideIn;
            animation-duration: 0.3s;
            animation-fill-mode: both;

            @include respond-below(sm) {
                position: relative;
                max-height: 300px;
                width: 100%;
            }
            &.dropdown-content-right {
                right: 0;
            }
        }
        .dropdown-content-menu {
            a {
                display: grid;
                align-items: center;
                padding: 7.5px;
                transition: background-color 0.2s ease-out;
                border-right: 1px solid $color-border;
                border-bottom: 1px solid $color-border;
                background-color: $color-white;
                grid-template-columns: 40px 1fr auto;
                &:focus,
                &:hover {
                    text-decoration: none;
                }
                &:hover {
                    background: color-gray(95);
                }
                > .icon {
                    color: $color-primary;
                    font-size: 20px;
                    text-align: center;
                }
                &.active {
                    background: $color-secondary-light;
                    .icon {
                        color: $color-secondary;
                    }
                    .badge {
                        background: $color-secondary;
                    }
                    &:focus,
                    &:hover {
                        background: $color-secondary-light-hover;
                        text-decoration: none;
                    }
                }
                &:last-child {
                    border-bottom: 0;
                }
            }
        }
        .dropdown-content-scrollable {
            display: grid;
            grid-template-rows: $fr-1;
            .dropdown-content-scrollable-container {
                display: grid;
                grid-template-rows: 39px $fr-1;
                .title {
                    display: grid;
                    align-items: center;
                    height: 39px;
                    padding: 0 10px;
                    border: 0;
                    border-bottom: solid 1px $color-border;
                    border-radius: 0;
                    grid-row: 1;
                    grid-template-columns: 1fr auto;
                    h3 {
                        margin: 0;
                        font-size: $font-size-sm;
                        font-weight: bold;
                    }
                    a {
                        font-size: $font-size-sm;
                        text-align: right;
                    }
                }
                .list {
                    overflow: auto;
                    grid-row: 2;
                }
                .list-item {
                    display: block;
                    padding: 10px;
                    transition: background-color 0.2s;
                    border-bottom: 1px solid $color-border;
                    color: inherit;
                    font-size: $font-size-sm;
                    text-decoration: none !important;
                    &:hover {
                        background-color: $color-list-item-hover;
                        cursor: pointer;
                    }
                }
            }
        }
        .dropdown-toggle-hover {
            > a {
                background: color-gray(90);
            }
            .dropdown-content {
                display: grid;
            }
        }
    }
    @media (max-width: $screen-sm-max) {
        .navbar .navbar-nav li {
            &.separator {
                display: none;
            }
            > a {
                padding: 15px 7px;
                > .icon,
                > .badge {
                    display: none;
                }
            }
        }
    }
}
