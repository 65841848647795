address-finder {
    display: block;
    position: relative;
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    .control {
        position: relative;
        &.loading::after {
            display: block;
            position: absolute;
            top: 0.625em;
            right: 0.625em;
            width: 1em;
            height: 1em;
            border: 2px solid $color-primary;
            border-radius: 290486px;
            border-top-color: transparent;
            border-right-color: transparent;
            content: '';
            animation: spin 0.5s infinite linear;
        }
    }
    .dropdown-menu {
        display: initial;
        width: calc(100% - 5px);
        overflow: scroll;
    }
}
