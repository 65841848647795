@import 'flows/flows';
@import 'list/list';
@import 'show/show';
@import 'wizard/wizard';
appeals {
    > ui-view {
        > .content {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 10px;
            > appeals-list {
                grid-column: 1;
            }
            > appeals-wizard {
                grid-column: 2;
            }
        }
    }
}
