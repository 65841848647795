coaches-show-progress-appeals {
    > .progress-component {
        > .header {
            a,
            a:visited {
                color: $color-text;
            }
        }
        > .statistics {
            grid-template-columns: 1fr !important;
            > .item > collection-selector {
                display: block;
                margin: 18px 15px;
            }
        }
    }
}
