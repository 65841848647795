financial-accounts-summary {
    .menu {
        display: grid;
        border-bottom: 1px solid $color-border;
        grid-template-columns: auto 1fr;
        > a {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 20px;
            border-right: 1px solid $color-border;
        }
    }
    .content,
    .loading {
        display: grid;
        align-items: center;
        height: 90px;
        padding: 0 $container-padding;
        background-color: $color-blue-deep-alpha-10;
    }
    .content {
        grid-template-columns: 1fr 1fr;
        .name {
            font-size: $font-size-ml;
        }
        .balance {
            font-size: $font-size-ml;
        }
        .navigation {
            font-size: $font-size-sm;
        }
    }
    .loading {
        grid-template-columns: 1fr;
    }
    a:hover {
        text-decoration: none;
    }
    .navigation .active {
        color: $text-muted;
    }
}
