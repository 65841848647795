@import 'celebrations/celebrations';
home-care {
    > h4.title {
        padding-bottom: 10px;
        border-bottom: 0;
    }
    .list {
        height: 157px;
        overflow: auto;
        @media (max-width: $screen-xs-max) {
            height: auto;
        }
    }
    .tab-pane > * {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}
home home-care .list .empty {
    align-self: flex-start;
    padding-top: 49px;
    @media (max-width: $screen-xs-max) {
        align-self: center;
        padding-top: 30px;
    }
}
