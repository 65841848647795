impersonation-bar {
    > a {
        display: grid;
        align-items: center;
        height: 30px;
        margin: 10px 0 10px 5px;
        padding: 5px $container-padding;
        transition: background-color 0.2s ease-out;
        border-radius: 9999px;
        background: $color-orange;
        color: $color-white;
        font-size: $font-size-sm;
        font-weight: bold;
        grid-auto-flow: column;
        grid-gap: 10px;
        &:hover {
            background: darken($color-orange, 10%);
            color: $color-white;
            text-decoration: none;
        }
    }
}
login impersonation-bar {
    > a {
        position: fixed;
        top: 0;
        right: 0;
        margin: 10px;
    }
}
