financial-accounts-entries {
    display: grid;
    height: 100%;
    overflow: hidden;
    grid-template-rows: 100%;
    grid-template-columns: auto 1fr;
    .filters {
        @extend .sidebar-expandable;
        .report-filter {
            padding: 10px 0;
            border-top: 1px solid $color-gray-blue;
            label {
                font-weight: normal;
            }
        }
    }
    .entries {
        width: 100%;
        overflow: hidden;
        > div {
            @extend .lists;
            display: grid;
            height: $full-container-height;
            grid-template-rows: auto auto auto $fr-1;
            > .menu {
                grid-column-gap: 10px;
                grid-template-columns: auto 1fr $search-bar-width;
                > .search-bar {
                    width: $search-bar-width;
                    .input-group-addon {
                        padding-right: 0;
                        background-color: $color-white;
                    }
                    input {
                        border-left-width: 0;
                    }
                }
            }
            > .content {
                overflow: auto;
                padding-bottom: $beacon-padding;
            }
        }
    }
    .entry {
        display: grid;
        align-items: center;
        padding: 20px $container-padding;
        border-bottom: 1px solid $color-border;
        grid-template-columns: 1fr 1fr 3fr 1fr 1fr;
        grid-gap: 10px;
        &.balance {
            background: color-gray(90);
            font-weight: bold;
        }
    }
    .title {
        padding: 5px $container-padding;
        font-size: $font-size-sm;
        a {
            display: flex;
            &:hover {
                text-decoration: none;
            }
            span {
                flex-grow: 1;
            }
        }
    }
    .totals {
        display: grid;
        margin: 20px 0;
        grid-template-columns: 5fr 2fr;
        .header,
        .content,
        .footer {
            display: flex;
            padding: 10px $container-padding;
        }
        .header,
        .name {
            font-weight: bold;
        }
        .header {
            border-bottom: 1px solid $color-border;
        }
        .name {
            flex-grow: 1;
        }
        .footer {
            border-top: 1px solid $color-border;
        }
    }
}
