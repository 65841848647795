@import 'item/item';
coaches-list {
    display: grid;
    height: $full-container-height;
    grid-template-rows: auto $fr-1;
    .menu {
        border-bottom: 1px solid $color-border;
        .heading {
            border-bottom: 1px solid $color-border;
        }
        pagination-sub-menu {
            padding: 5px 0;
            border-bottom: 0;
        }
    }
    .list {
        overflow-y: auto;
        .blankslate {
            margin-top: 10px;
        }
    }
}
