@use 'sass:math';
preferences-notifications {
    .panel-heading {
        .row {
            > div:first-child {
                line-height: 47px;
            }
        }
    }
    .list-group {
        .pull-right {
            margin-left: math.div($gutter, 2);
        }
        .form-group {
            margin-bottom: 0;
        }
        &:first-child .list-group-item {
            padding: 5px 15px;
        }
        .col-sm-1 {
            white-space: nowrap;
        }
    }
    .actions {
        padding: 10px $container-padding;
        text-align: right;
    }
}
