modal.multiple-contact {
    .modal-body {
        padding: 0;
        .table {
            margin-bottom: 0;
            tr {
                th:first-child,
                td:first-child {
                    padding-left: 15px;
                }
                th:last-child,
                td:last-child {
                    padding-right: 15px;
                }
            }
            tr:last-child td {
                padding-bottom: 15px;
            }
        }
    }
}
