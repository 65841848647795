modal.contacts-bulk-actions-contact-map {
    > .modal-dialog > form > .modal-content > .modal-body,
    .map,
    .addresses {
        height: calc(100vh - 104px);
        overflow: auto;
    }
    .modal-body {
        display: grid;
        padding: 0;
        grid-template-columns: 1fr 310px;
    }
    .map {
        position: relative;
        .empty-map,
        ng-map {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
        .empty-map {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: color-gray(25);
            color: $color-white;
            font-size: 75px;
        }
        ng-map .map-info-window {
            min-width: 200px;
            font-family: $font-primary;
            font-size: $font-size-md;
        }
    }
    .panel.panel-default {
        margin: 0;
        border-width: 0 0 1px;
        .panel-heading {
            line-height: 34px;
            i {
                font-size: $font-size-sm;
                line-height: 37px;
            }
            img {
                margin-right: 10px;
            }
        }
        .card {
            margin-bottom: 0;
            padding: 5px 10px;
            transition: background-color 0.2s ease-out;
            border-bottom: 1px solid $color-border;
            background-color: $color-white;
            cursor: pointer;
            &:last-child {
                border-bottom-width: 0;
            }
            &.card-selected {
                background-color: $color-secondary-alpha-20;
            }
        }
    }
}
