@import 'appeals/appeals';
@import 'care/care';
@import 'referrals/referrals';
@import 'weeklyActivity/weeklyActivity';
@import 'welcome/welcome';
home {
    display: block;
    height: 100%;
    background: $color-bg-dashboard;
    overflow-x: hidden;
    overflow-y: auto;
    .content {
        display: grid;
        max-width: 1280px;
        margin: 0 auto;
        padding: $container-padding $container-padding $beacon-padding;
        grid-template-rows: auto auto auto auto $fr-1;
        grid-template-columns: 100%;
        grid-gap: 45px;
        @media (max-width: $screen-xs-max) {
            grid-gap: 24px;
        }
        .this-week {
            .this-week-dropdown {
                display: grid;
                align-items: center;
                grid-gap: 10px;
                grid-auto-flow: column;
            }
            .card .card-content {
                display: grid;
                align-items: stretch;
                padding: 0;
                grid-template-columns: 1fr 1fr 1fr;
                > * {
                    display: flex;
                    flex-direction: column;
                    border-right: 1px solid $color-border;
                    &:last-child {
                        border-right: 0;
                    }
                }
                @media (max-width: $screen-xs-max) {
                    grid-template-columns: 1fr;
                    > * {
                        border-top: 1px solid $color-border;
                        border-right: 0;
                        &:first-child {
                            border-top: 0;
                        }
                    }
                }
            }
        }
        .grid {
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: 1fr;
            grid-gap: 24px;
            &.grid-goal-balance {
                grid-template-columns: 2fr 1fr;
            }
            @media (max-width: $screen-xs-max) {
                grid-auto-flow: row;
                &.grid-goal-balance {
                    grid-template-columns: 1fr;
                }
            }
        }
    }
    .title {
        margin: 0 18px;
        padding: $container-padding 10px;
        border-bottom: 1px solid $color-border;
    }
    .list {
        display: flex;
        flex-grow: 1;
        ul {
            width: 100%;
            margin: 0 18px;
            padding-left: 10px;
            list-style: none;
        }
        .loading {
            align-self: center;
            width: 100%;
            text-align: center;
            justify-self: center;
        }
        .empty {
            align-self: center;
            width: 100%;
            padding: 30px 0;
            text-align: center;
            justify-self: center;
        }
    }
    .item {
        a {
            display: grid;
            padding: 10px $container-padding 10px 0;
            transition: color 0.25s ease-out;
            border-bottom: 1px solid $color-border;
            grid-template-columns: 1fr auto;
            grid-column-gap: 10px;
            &:hover {
                text-decoration: none;
            }
            .description {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                grid-row: 2;
            }
            .complete {
                align-self: center;
                grid-row: 1 / span 2;
                &:hover {
                    color: $color-success;
                    > i {
                        font-weight: 900;
                        &::before {
                            content: '\F058';
                        }
                    }
                }
            }
        }
        &:last-child a {
            border-bottom: 0;
        }
    }
}
