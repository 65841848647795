// Make sure to use basic css styles as we want older browsers to render this component
unsupported {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-white;
    text-align: center;
    overflow: auto;
    .menu {
        border-top: 5px solid $color-gold;
        .navbar-brand {
            padding: 10px 15px;
            img {
                height: 30px;
            }
        }
    }
    .mobile {
        padding: 0 $container-padding;
        h3 {
            margin: 10px 0 20px;
        }
        img {
            width: 60%;
            max-width: 500px; // tablet sanity
        }
        &-ios {
            p {
                font-size: $font-size-sm;
            }
        }
    }
    .mobile-screenshot {
        height: 400px;
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        &-ios {
            background-image: url('images/mobile/ios_homepage.png');
        }
        &-android {
            background-image: url('images/mobile/android_homepage.png');
        }
    }
    .desktop {
        padding: 0 $container-padding;
        h3 {
            margin-top: 0;
        }
        img {
            width: 60%;
            max-width: 500px; // tablet sanity
        }
        p {
            padding: 20px 0 10px;
        }
        .btn img {
            width: 50px;
            margin-right: 10px;
        }
    }
    .bottom {
        margin-bottom: 10px;
        padding: 10px $container-padding;
        border-radius: 5px;
        background: $color-bg;
    }
}
