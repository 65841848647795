merge-preferences {
    .well {
        label {
            display: block;
            text-align: center;
        }
    }
    .merge-direction {
        @include respond-above(xs) {
            padding-bottom: 20px;
        }
        .fa {
            line-height: 101px;
            @include respond-above(xs) {
                transform: rotate(90deg);
                font-size: 3em;
                line-height: 1;
            }
        }
    }
}
