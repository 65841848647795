home-welcome {
    display: block;
    margin-bottom: 30px;
    background: $color-white;
    div {
        max-width: 1280px;
        margin: 0 auto;
        padding: 50px $container-padding;
    }
    h2 {
        margin: 0;
    }
}
