home-appeals {
    .appeal-title {
        display: grid;
        margin-top: 0;
        margin-bottom: 20px;
        grid-template-columns: 1fr auto;
        grid-gap: 10px;
        .appeal-name {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
    .card-content {
        align-items: center;
        justify-content: center;
    }
    .appeal {
        width: 100%;
    }
    .empty {
        padding: 30px 0;
    }
}
