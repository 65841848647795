contacts-flows-contact {
    display: block;
    padding: 10px;
    transition: background-color 0.2s ease-out;
    background-color: $color-white;
    color: $color-text;
    font-size: $font-size-sm;
    cursor: move;
    user-select: none;
    &:hover {
        background-color: $color-list-item-hover;
    }
    &.active {
        background-color: $color-list-item-active;
        &:hover {
            background-color: $color-list-item-active-hover;
        }
    }
    .details {
        display: grid;
        grid-template-columns: 25px 1fr 25px;
        grid-gap: 10px;
        avatar {
            .avatar,
            .avatar img {
                width: 25px;
                height: 25px;
            }
        }
        .name {
            color: $color-blue;
            font-weight: bold;
            &:hover {
                text-decoration: underline;
            }
        }
        .star {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
