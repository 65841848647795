contributions {
    .fourteen-month-report-table {
        $row-stripe-color: #f9f9f9;
        $month-columns: 14;
        $total-columns: $month-columns + 2;
        $header-cells: $total-columns + 4;
        display: grid;
        grid-template-columns: 5fr [months-start] repeat($month-columns, 1fr) 1fr;
        & > .grid-start-at-months-start {
            grid-column-start: months-start;
        }
        & > * {
            padding: 8px 4px;
            border-bottom: 1px solid $color-border;
            &.spacing-left {
                padding-left: 16px;
            }
            &.spacing-right {
                padding-right: 16px;
            }
        }
        & > .year-row {
            position: sticky;
            top: 0;
            background-color: $color-white;
            font-size: 1.3em;
            font-weight: bold;
            & + .year-row > * {
                border-left: 1px solid color-gray(80);
            }
            &.year {
                text-align: center;
            }
        }
        & > .month-row {
            position: sticky;
            top: 46px;
            border-top: 1px solid $color-border;
            border-bottom: 2px solid $color-border;
            background-color: $color-white;
            font-weight: bold;
        }
        // Stripe every other row of the table with a different background
        &:not(.expanded) > * {
            @for $i from 1 through $total-columns {
                &:nth-child(#{$total-columns * 2}n + #{$header-cells + $i}) :nth-last-child(n + #{$total-columns + 1}) {
                    background-color: $row-stripe-color;
                }
            }
        }
        &.expanded {
            $expanded-additional-cols: 4;
            $total-columns-expanded: $total-columns + $expanded-additional-cols;
            $header-cells-expanded: $header-cells + $expanded-additional-cols;
            grid-template-columns: 5fr repeat($expanded-additional-cols, 1fr) [months-start] repeat($month-columns, 1fr) 1fr;
            .currency-expand {
                grid-column: span $expanded-additional-cols + 1;
            }
            .totals-expand {
                grid-column: span 3;
            }
            // Stripe every other row of the table with a different background
            & > * {
                @for $i from 1 through $total-columns-expanded {
                    &:nth-child(#{$total-columns-expanded * 2}n + #{$header-cells-expanded + $i})
                        :nth-last-child(n + #{$total-columns-expanded + 1}) {
                        background-color: $row-stripe-color;
                    }
                }
            }
        }
    }
}
ui-view > reports > .reports > ui-view > contributions > .content {
    padding: 0;
    padding-bottom: $beacon-padding;
}
@media print {
    // sass-lint:disable-block no-ids
    #beacon-container {
        display: none;
    }
    ui-view > reports > .reports > ui-view > contributions {
        height: auto;
        .heading {
            padding-left: 0 !important;
        }
        .fourteen-month-report-table {
            & > .year-row,
            .month-row {
                position: static;
            }
            & > * {
                padding: 4px 2px;
                font-size: 0.8em;
                &.spacing-left {
                    padding-left: 0;
                }
                &.spacing-right {
                    padding-right: 0;
                }
            }
        }
        > .content {
            padding-bottom: 0;
        }
    }
}
