@import 'accountLists/accountLists';
@import 'contacts/contacts';
preferences-organizations {
    .heading {
        display: grid;
        align-items: center;
        height: 65px;
        margin-top: -10px !important;
        padding: 0 $container-padding !important;
        grid-template-columns: auto 250px;
    }
}
