@import 'progressbar/progressbar';
@import 'sidebar/sidebar';
@import 'summary/summary';
appeals-show {
    display: grid;
    height: $full-container-height;
    overflow: hidden;
    grid-template-columns: auto 1fr;
    grid-template-rows: $fr-1;
    table {
        tr {
            th,
            td {
                &:first-child {
                    padding-left: $container-padding;
                }
                &:last-child {
                    padding-right: $container-padding;
                }
            }
        }
        tbody tr {
            transition:
                background-color 0.2s ease-out,
                border-color 0.2s ease-out;
            background-color: $color-white;
            cursor: pointer;
            .actions {
                transition: opacity 0.2s ease-out;
                text-align: right;
                opacity: 0;
                a {
                    margin-left: 5px;
                }
            }
            &:hover {
                background-color: $color-list-item-hover;
                .actions {
                    opacity: 1;
                }
            }
            &.selected {
                background-color: $color-list-item-active;
                &:hover {
                    background-color: $color-list-item-active-hover;
                }
            }
        }
    }
    pagination {
        text-align: center;
    }
    .spacer {
        flex-grow: 1;
    }
    appeal-sidebar {
        grid-column: 1;
        grid-row: 1;
    }
    .content {
        overflow: auto;
        grid-row: 1;
        grid-column: 2;
        .wrapper {
            display: grid;
            height: $full-container-height;
            grid-template-rows: auto $fr-1;
        }
        .menu {
            display: flex;
            align-items: center;
            padding: $container-padding;
            transition: background-color 0.2s ease-out;
            border-bottom: 1px solid $color-border;
            grid-row: 1;
            grid-column: 1;
            &.selected {
                background-color: $color-nav-selected;
            }
            .selected-count {
                margin-left: 10px;
                color: $color-white;
                a {
                    color: $color-white;
                    cursor: pointer;
                }
            }
        }
        .list {
            overflow: auto;
            grid-column: 1;
            grid-row: 2;
            .appeal {
                display: flex;
                align-items: center;
                padding: 10px $container-padding;
                h2,
                .inline-input.header {
                    margin: 0;
                }
                .amount {
                    max-width: 100px;
                }
            }
            .blankslate {
                margin: 8px $container-padding;
            }
            ui-view .full-page {
                height: 100%;
            }
        }
    }
    .statistics {
        display: grid;
        margin-top: 10px;
        grid-template-columns: repeat(5, 1fr);
        .statistic {
            color: $color-white;
            &:hover {
                text-decoration: none;
            }
            &-container {
                display: grid;
                align-items: center;
                height: 100px;
                font-weight: bold;
                text-align: center;
                grid-template-columns: auto 1fr;
                &:first-child {
                    grid-template-columns: 1fr;
                }
            }
            &-default {
                background: $color-text;
            }
            &-warning {
                background: $color-warning;
            }
            &-success {
                background: $color-success;
            }
            &-danger {
                background: $color-danger-dark;
            }
        }
        .number {
            margin: 0;
            font-weight: bold;
        }
        .arrow {
            width: 0;
            height: 0;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            &-default {
                border-left: 20px solid $color-text;
            }
            &-warning {
                border-left: 20px solid $color-warning;
            }
            &-danger {
                border-left: 20px solid $color-danger-dark;
            }
        }
    }
    .panel-tour .panel-body {
        display: grid;
        align-items: center;
        grid-template-columns: 50px 1fr auto;
        grid-gap: 10px;
        &::before,
        &::after {
            content: none;
        }
    }
    ui-view {
        display: block;
        padding-bottom: $beacon-padding;
    }
}
