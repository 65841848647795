@use 'sass:math';

@import '../styles/variables';
modal.coaching-questions {
    .modal-body {
        padding: $gutter;
    }
    .questions-progress-bar {
        $unanswered-question-color: #ededed;
        $unanswered-question-size: 6px;
        $answered-question-size: 10px;
        $line-size: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        & > li {
            position: relative;
            width: 30px;
            height: $line-size;
            background: $color-blue;
            list-style: none;
            content: ' ';
            &::before {
                position: absolute;
                top: -(math.div($unanswered-question-size, 2) - math.div($line-size, 2));
                left: -(math.div($unanswered-question-size, 2));
                width: $unanswered-question-size;
                height: $unanswered-question-size;
                border-radius: 50%;
                background: $color-blue;
                content: '';
            }
            &:last-child {
                width: 0;
            }
            &.active {
                background: $unanswered-question-color;
                &::before {
                    top: -(math.div($answered-question-size, 2) - math.div($line-size, 2));
                    left: -(math.div($answered-question-size, 2));
                    width: $answered-question-size;
                    height: $answered-question-size;
                }
                & ~ li {
                    background: $unanswered-question-color;
                }
                & ~ li::before {
                    background: $unanswered-question-color;
                }
            }
        }
    }
    .question-short-answer {
        width: 100%;
        border: 1px solid black;
        border-radius: 3.5px;
        margin: 6px 0;
        padding: math.div($gutter, 2);
    }

    .radio-pill {
        .radio-pill-item {
            display: inline-block;
            position: relative;
            input {
                appearance: none;
                position: absolute;
                z-index: 2;
            }

            label {
                border-radius: 2em;
                border: 2px solid transparent;
                padding: 0.25em 0.75em;
                margin-right: 0.5em;
            }

            input:hover ~ label,
            label:hover {
                background: $color-blue-lighter;
                border-color: $color-blue;
                cursor: pointer;
            }

            input:checked + label {
                background: $color-blue;
                color: #fff;
            }
        }
    }

    .action-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: $gutter;
    }
}
