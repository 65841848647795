.sidebar {
    background-color: $color-filter-bar;
    color: $color-white;
    .heading {
        display: flex;
        align-items: center;
        margin: 13px 0 0;
        padding: 10px $container-padding $container-padding;
        border-bottom: 1px solid $color-gray-blue;
        i {
            margin-right: 5px;
        }
    }
    .nav.nav-pills {
        li {
            + li {
                margin: 0;
            }
            a {
                display: flex;
                align-items: center;
                margin: 0;
                padding: 10px $container-padding;
                transition: background-color 0.2s ease-out;
                border-bottom: 1px solid $color-gray-blue;
                border-radius: 0;
                background-color: transparent;
                color: $color-white;
                cursor: pointer;
                > *:first-child {
                    flex-grow: 1;
                }
                > i {
                    margin-left: 5px;
                    transition: opacity 0.2s ease-out;
                    opacity: 0.2;
                }
                &:hover {
                    background-color: $color-filter-bar-dark;
                    i {
                        opacity: 0.5;
                    }
                }
                &:focus {
                    outline: none;
                }
            }
            &.active {
                a {
                    background-color: $color-filter-bar-dark;
                    i {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
