@import 'drawer/drawer';
@import 'item/item';
$search-bar-width: 275px;
$task-menu-breakpoint: 1000px;
$drawer-menu-breakpoint: 1400px;
$task-drawer-menu-breakpoint: 1200px; // sanity width
tasks-list {
    @extend .lists;
    display: grid;
    height: 100%;
    overflow: hidden;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto $fr-1;
    .search-bar {
        .input-group-addon {
            padding-right: 0;
            background-color: $color-white;
        }
        input {
            border-left-width: 0;
        }
    }
    > .menu {
        max-height: 153px; // sanity width
        grid-column-gap: 10px;
        grid-template-columns: 1fr auto $search-bar-width;
        grid-column: 1;
        grid-row: 1;
        grid-row-gap: 5px;
        &.filters-expanded {
            min-width: 465px; //sanity width
            @media (max-width: $task-menu-breakpoint) {
                grid-template-rows: repeat(2, $fr-1);
                grid-template-columns: repeat(2, 1fr);
                > .selected-count {
                    grid-row: 2;
                    grid-column: 1;
                }
                > .search-bar {
                    width: 100%;
                    grid-row: 1;
                    grid-column: 1 / span 2;
                }
                > div.menu-buttons {
                    width: 100%;
                    grid-row: 2;
                    grid-column: 2;
                    .dropdown-menu {
                        left: auto !important;
                    }
                }
            }
            @media (min-width: $task-menu-breakpoint) {
                > div.menu-buttons .show-small {
                    display: none;
                }
            }
        }
        &.filters-collapsed {
            min-width: 510px; //sanity width
            $breakpoint: 810px; // calculation wraps early - slightly more than $task-menu-breakpoint - $sidebar-width - $sidebar-collapse-width
            @media (max-width: $breakpoint) {
                grid-template-rows: repeat(2, $fr-1);
                grid-template-columns: repeat(2, 1fr);
                > .selected-count {
                    grid-row: 2;
                    grid-column: 1;
                }
                > .search-bar {
                    width: 100%;
                    grid-row: 1;
                    grid-column: 1 / span 2;
                }
                > div.menu-buttons {
                    width: 100%;
                    grid-row: 2;
                    grid-column: 2;
                    .dropdown-menu {
                        left: auto !important;
                    }
                }
            }
            @media (min-width: $breakpoint) {
                > div.menu-buttons .show-small {
                    display: none;
                }
            }
        }
        > .selected-count {
            display: grid;
            color: $color-white;
            grid-column-gap: 15px;
            grid-template-rows: $fr-1 0;
            grid-template-columns: auto 1fr; // 0 row for odd chrome display
            grid-column: 1;
            .dropdown {
                grid-row: 1;
                grid-column: 1;
            }
            .count {
                font-size: $font-size-sm;
                line-height: 33px;
                white-space: nowrap;
                grid-row: 1;
                grid-column: 2;
            }
        }
        > .search-bar {
            width: $search-bar-width;
        }
        > div.menu-buttons {
            display: grid;
            min-width: 240px;
            padding: 0;
            grid-template-columns: 1fr auto;
            .btn-group {
                white-space: nowrap;
            }
        }
        &.drawer-open {
            min-width: 280px;
            @media (max-width: $drawer-menu-breakpoint) {
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, $fr-1);
                > div.menu-buttons {
                    grid-row: 2;
                    grid-column: 2;
                }
                > div.selected-count {
                    grid-row: 2;
                }
                > .search-bar {
                    width: 100%;
                    grid-column: 1 / span 2;
                }
            }
            @media (max-width: $task-drawer-menu-breakpoint) {
                grid-template-columns: 1fr;
                grid-template-rows: repeat(1, $fr-1);
                > div.menu-buttons {
                    width: 100%;
                    justify-items: start;
                    grid-row: 2;
                    grid-column: 1 / span 3;
                }
                > div.selected-count {
                    grid-row: 3;
                    grid-column: 1 / span 3;
                }
                > .search-bar {
                    width: 100%;
                    grid-column: 1 / span 2;
                }
            }
        }
        &.in-contact {
            grid-template-columns: 1fr auto;
            grid-template-rows: 1fr;
            > div.selected-count {
                grid-row: 1;
                grid-column: 1;
            }
            > div.menu-buttons {
                min-width: auto;
                grid-row: 1;
                grid-column: 2;
            }
            @media (max-width: $task-drawer-menu-breakpoint) {
                &.drawer-open.selected > div.menu-buttons > .btn-group > .btn {
                    display: none;
                }
            }
        }
    }
    > .pagination-sub-menu {
        display: grid;
        padding: 5px $container-padding;
        border-bottom: 1px solid $color-border;
        grid-row: 2;
        grid-column: 1;
        grid-column-gap: 15px;
        grid-template-columns: 1fr auto;
        > pagination-sub-menu {
            padding: 0;
            border-bottom: 0;
            > div.sub-line-1 {
                display: grid;
                grid-template-columns: 1fr auto;
                grid-column-gap: 10px;
                &.in-contact {
                    grid-template-columns: auto minmax(100px, 300px) auto;
                    .chosen-container-single .chosen-single {
                        height: 36px; // so dropdowns aren't off by a pixel or two
                    }
                    > div.action-filter {
                        display: grid;
                        grid-template-columns: auto 1fr;
                        grid-column-gap: 10px;
                        &.in-drawer {
                            grid-template-columns: 1fr;
                        }
                    }
                }
            }
        }
        > .star {
            display: flex;
            align-items: center;
        }
        @media (max-width: $task-drawer-menu-breakpoint) {
            &.in-contact.drawer-open > pagination-sub-menu > .tags {
                display: none;
            }
        }
    }
    > .sub-menu {
        display: grid;
        padding: 10px 15px;
        border-bottom: 2px solid $color-border;
        grid-row: 2;
        grid-column: 1;
        grid-template-columns: minmax(0, $search-bar-width) minmax(10px, 1fr) minmax(0, $search-bar-width);
        &.in-drawer {
            grid-template-columns: 1fr;
        }
    }
    > .content {
        overflow: auto;
        grid-row: 3;
        grid-column: 1;
    }
    > side-drawer {
        grid-row: 1 / span 3;
        grid-column: 2;
    }
    .task-category {
        &-title {
            display: flex;
            align-items: center;
            padding: 10px 15px;
            border-bottom: 2px solid $color-border;
            background-color: $color-white;
            font-size: 18px;
            cursor: pointer;
            &-text {
                flex-grow: 1;
            }
            &.today {
                border-color: $color-secondary;
            }
            &.overdue {
                border-color: $color-orange;
            }
            &.upcoming {
                border-color: $color-green-dark;
            }
            &.no-due-date {
                border-color: $color-cyan-dark;
            }
            > i {
                transition: transform 0.2s ease-out;
            }
        }
        &-list {
            margin-bottom: 0;
            .list-group-item {
                margin: 0;
                padding: 0;
                border: 0;
                border-bottom: 1px solid $color-border;
            }
        }
    }
}
