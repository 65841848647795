@import 'chart/chart';
donations {
    display: flex;
    flex-direction: column;
    h4 {
        margin: 0;
    }
    .table {
        tfoot {
            .total {
                white-space: nowrap;
            }
        }
        > thead:first-child > tr:first-child > th {
            border-top: 2px solid color-gray(90);
            border-bottom: 2px solid color-gray(90);
        }
        &.in-contact > thead:first-child > tr:first-child > th {
            border-top: 0;
        }
    }
    .nav {
        display: flex;
        align-items: center;
        padding: 10px 0;
        h4 {
            flex-grow: 1;
            font-weight: 600;
        }
    }
    > donations-chart {
        > .panel {
            margin-bottom: 0 !important;
        }
    }
    .blankslate.in-contact {
        margin-top: 10px;
    }
    .foreign {
        border-bottom: 1px $color-text dashed;
    }
}
reports donations {
    .table-responsive {
        margin: 0 (-$container-padding);
        .table {
            tr > th,
            tr > td {
                &:first-child {
                    padding-left: $container-padding;
                }
                &:last-child {
                    padding-right: $container-padding;
                }
            }
        }
    }
}
