pagination-sub-menu {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    border-bottom: 1px solid color-gray(80);
    > div.filters {
        font-size: $font-size-md;
    }
    .tags {
        margin-left: 5px;
        .tag {
            margin: 0;
        }
    }
    .page-size {
        flex-grow: 1;
        text-align: right;
        .chosen-container {
            width: 60px !important;
            text-align: left;
        }
    }
    .loading {
        font-size: $font-size-md;
    }
}
