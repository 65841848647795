contact-tasks {
    tasks-list {
        height: calc(100% + 20px);
        margin: -10px -15px;
        grid-template-rows: auto auto auto $fr-1;
        > .pagination-sub-menu {
            display: grid;
            align-items: center;
            padding: 10px $container-padding;
            grid-row: 1;
            grid-template-columns: 1fr auto auto;
            .toggle-menu {
                color: $color-text;
                font-size: 18px;
                i {
                    transition: transform 0.2s ease-out;
                }
            }
        }
        > .menu {
            padding: 10px $container-padding;
            grid-row: 2;
        }
        > .sub-menu {
            grid-row: 3;
        }
        > .content {
            grid-row: 4;
        }
        > .menu,
        > .sub-menu {
            height: 56px;
            transition: all ease-out 0.2s;
            &.ng-hide {
                height: 0;
                padding: 0 $container-padding;
                overflow: hidden;
            }
        }
        > side-drawer {
            grid-row: 1 / span 4;
        }
    }
}
.contact-drawer contact-tasks tasks-list > .pagination-sub-menu {
    grid-template-columns: 1fr auto;
}
