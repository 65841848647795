modal.address-modal {
    .empty-map {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 200px;
        background-color: color-gray(25);
        color: $color-white;
        font-size: 75px;
        line-height: 200px;
        text-align: center;
    }
    ng-map {
        > div {
            min-height: 200px;
        }
        &:first-child {
            margin: -15px -15px 15px;
        }
    }
}
