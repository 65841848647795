@import 'tags/tags';
tasks-filter {
    @extend .sidebar-expandable;
    > .content {
        .title {
            margin-bottom: 10px;
        }
    }
    .toggle-filters {
        padding-bottom: 10px;
        color: $color-white;
        line-height: 29px;
        .btn-group-justified {
            display: flex;
            .btn {
                flex-grow: 1;
                transition: background-color 0.2s ease-out;
                &:focus {
                    outline: none;
                }
                &.active {
                    background-color: $color-sidebar-bg;
                    cursor: default;
                }
            }
        }
    }
}
