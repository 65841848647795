donations-chart {
    .card {
        height: auto;
        .card-heading {
            display: grid;
            grid-template-columns: 1fr 3fr 1fr;
            grid-gap: 10px;
            @media (max-width: $screen-xs-max) {
                grid-template-columns: 1fr;
            }
            .card-heading-center {
                display: grid;
                align-items: center;
                justify-content: center;
                grid-gap: 10px;
                grid-auto-flow: column;
                > div {
                    white-space: nowrap;
                }
                @media (max-width: $screen-xs-max) {
                    display: none;
                }
            }
            > a {
                text-align: right;
            }
        }
        .card-content {
            justify-content: center;
            height: 290px;
            .chart {
                max-width: 800px;
                margin: 0 auto;
            }
            .blankslate {
                height: 100%;
            }
        }
    }
    .color-pledges {
        width: 15px;
        height: 5px;
        background-color: $color-pledges;
    }
    .color-monthly-goal {
        width: 15px;
        height: 5px;
        background-color: $color-monthly-goal;
    }
    .color-monthly-average {
        width: 15px;
        height: 5px;
        background-color: $color-monthly-average;
    }
}
