home-referrals {
    .card .card-content {
        padding: 0;
        .tab-content,
        .tab-pane {
            height: 100%;
        }
    }
    home-referrals-list {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
}
