appeals-flows-column {
    display: grid;
    position: relative;
    min-width: $flow-column-min-width;
    max-width: $flow-column-max-width;
    border: 1px solid $color-border;
    background-color: color-gray(95);
    overflow: auto;
    grid-template-rows: 44px 1fr auto;
    > .heading {
        display: grid;
        align-items: center;
        padding: 0 $container-padding;
        border-bottom: 5px solid $color-border;
        background-color: $color-white;
        font-size: $font-size-md;
        grid-gap: 10px;
        grid-template-columns: 1fr auto auto;
        .name {
            height: 22px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        i {
            color: $color-blue;
        }
        &.color-danger {
            border-bottom-color: $color-danger;
        }
        &.color-text {
            border-bottom-color: $color-text;
        }
        &.color-committed {
            border-bottom-color: $color-gray-cyan;
        }
        &.color-received {
            border-bottom-color: $color-orange-dark;
        }
        &.color-given {
            border-bottom-color: $color-gold;
        }
        &.color-border {
            border-bottom-color: $color-border;
        }
        a i {
            color: $color-text;
        }
    }
    > .actions > div {
        padding: 10px;
        border-top: 1px solid $color-border;
        background-color: $color-white;
        .btn {
            margin-bottom: 0;
        }
    }
    > .content {
        overflow: auto;
        > * {
            border-bottom: 1px solid $color-border;
            &:last-child {
                border-bottom: 0;
            }
        }
    }
    a,
    a:link,
    a:hover {
        text-decoration: none;
    }
    .dropzones {
        display: none;
        position: absolute;
        top: 44px;
        right: 0;
        bottom: 0;
        left: 0;
        .dropzone {
            display: grid;
            align-items: center;
            transition: background-color 0.5s;
            border: 2.5px dashed $color-blue;
            background-color: $color-blue-deep-alpha-60;
            color: $color-white;
            justify-items: center;
            h4 {
                font-weight: bold;
            }
            &:hover {
                background-color: $color-blue-deep-alpha-80;
            }
            &.disabled {
                border-color: color-gray(80);
                background-color: color-gray(90);
                color: $color-text;
                opacity: 0.9;
                &:hover {
                    background-color: color-gray(85);
                }
            }
        }
    }
}
.as-sortable-un-selectable appeals-flows-column .dropzones {
    display: grid;
}
.flow-columns .as-sortable-drag {
    position: fixed;
    appeals-flows-contact {
        border: 1px solid $color-border;
        background-color: $color-list-item-hover;
        box-shadow: 0 3px 9px $color-black-alpha-50;
        &.active {
            background-color: $color-list-item-active-hover;
        }
    }
}
