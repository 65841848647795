$flow-menu-height: 65px;
$flow-column-min-width: 240px;
$flow-column-max-width: 380px;
$flow-container-height: calc(
    #{$full-container-height} - #{$flow-menu-height}
); //workaround for safari jumping containers
@import 'column/column';
@import 'contact/contact';
@import 'setup/setup';
contacts-flows {
    .flows {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: $flow-menu-height $fr-1;
        .menu {
            display: grid;
            align-items: center;
            height: $flow-menu-height;
            padding: 0 $container-padding;
            border-bottom: 1px solid $color-border;
            grid-template-columns: 1fr auto auto 275px;
            grid-gap: 10px;
            .input-group-addon {
                padding-right: 0;
                background-color: $color-white;
            }
            input[type='text'] {
                border-left: 0;
            }
        }
        .flow-columns {
            display: grid;
            height: $flow-container-height;
            padding: 10px $container-padding;
            overflow: auto;
            grid-auto-flow: column;
            grid-auto-columns: minmax($flow-column-min-width, $flow-column-max-width);
            grid-gap: 10px;
            grid-row: 2;
        }
        div[ui-view='drawer'] {
            grid-row: 1 / span 2;
            grid-column: 2;
        }
    }
}
