@import 'commitment/item/item';
@import 'newsletter/item/item';
fix {
    .fix-item-content {
        display: grid;
        grid-template-columns: 1fr 150px;
        grid-gap: 10px;
    }
    .panel {
        margin-bottom: 7.5px;
        .panel-heading {
            display: grid;
            align-items: center;
            border-bottom: 0;
            background: $color-white;
            grid-column-gap: 10px;
            grid-template-columns: 50px 1fr 150px;
            .contact h4 {
                margin: 0;
            }
        }
        .panel-body {
            border-top: 1px solid $color-border;
            background-color: color-gray(95);
            input.form-control {
                padding: 0;
                border: 0;
                border-bottom: 1px solid color-gray(70);
                border-radius: 0;
                outline: 0;
                background: transparent;
                font-size: 0.9rem;
                font-weight: 600;
                line-height: 18px;
                box-shadow: none;
            }
            .field {
                display: grid;
                align-items: center;
                grid-template-columns: 1fr 80px 1fr 25px;
                grid-column-gap: 10px;
                .primary {
                    text-align: center;
                }
            }
        }
    }
}
