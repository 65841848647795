fix-commitment-info-item {
    .panel {
        .panel-heading {
            grid-template-columns: 50px 1fr 50% !important;
            .selector {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: 35px 35px;
                grid-gap: 10px;
                .status {
                    grid-column: 1 / span 3;
                }
            }
        }
        .panel-body {
            padding: 5px 0;
            .table {
                margin-bottom: 0;
                th,
                td {
                    &:first-child {
                        padding-left: 15px;
                    }
                    &:last-child {
                        padding-right: 15px;
                    }
                }
            }
        }
    }
}
