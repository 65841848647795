goal {
    .indicator {
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-right: 2px;
        margin-bottom: -3px;
        border-radius: 18px;
    }
    .indicator-goal {
        border: 2px solid color-gray(60);
    }
    .indicator-primary,
    .goal-progress-primary {
        background: linear-gradient(180deg, $color-goal-light 0%, $color-goal 100%);
    }
    .indicator-secondary,
    .goal-progress-secondary {
        border: 5px solid $color-goal;
    }
    .goal {
        position: relative;
        width: 100%;
        height: 54px;
        margin-bottom: 15px;
        padding: 2px;
        border: 2px solid color-gray(60);
        border-radius: 50px;
    }
    .goal-progress {
        position: absolute;
        left: 2px;
        width: 0%;
        min-width: 46px;
        max-width: 99.6%;
        height: 46px;
        transition: width 1s ease-out;
        border-radius: 46px;
    }
    .statistics {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
        grid-gap: 10px;
    }
}
