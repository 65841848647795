$side-drawer-width: 450px;
$side-drawer-title-height: 63px;
side-drawer {
    display: grid;
    width: $side-drawer-width;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    border-left: 1px solid $color-border;
    background: $color-white;
    grid-template-rows: $side-drawer-title-height $fr-1;
    .drawer-title {
        width: 100%;
        height: $side-drawer-title-height;
        margin: 0;
        border-bottom: 1px $color-border solid;
        line-height: $side-drawer-title-height;
        overflow: hidden;
        grid-row: 1;
        &-container {
            display: grid;
            overflow: hidden;
            grid-template-columns: 1fr auto;
        }
        &-text {
            padding-left: $container-padding;
            text-overflow: ellipsis;
        }
        &-close {
            width: 60px;
            transition: color 0.2s;
            border-left: 1px $color-menu-border solid;
            color: color-gray(90);
            text-align: center;
            cursor: pointer;
            &:hover {
                color: color-gray(35);
            }
        }
    }
    ng-transclude {
        position: relative;
        overflow: hidden;
        grid-row: 2;
    }
}
