appeals-progressbar {
    .info {
        margin: 0 6px;
        font-size: $font-size-sm;
        text-align: right;
        .color-committed {
            color: $color-gray-cyan;
        }
        .color-received {
            color: $color-orange-dark;
        }
        .color-given {
            color: $color-gold;
        }
    }
    .progress {
        position: relative;
        height: 24px;
        margin: 0;
        .progress-bar {
            position: absolute;
            left: 0;
            line-height: 24px;
        }
        .color-committed {
            background-color: $color-gray-cyan;
        }
        .color-received {
            background-color: $color-orange-dark;
        }
        .color-given {
            background-color: $color-gold;
        }
    }
}
