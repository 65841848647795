notifications {
    .list-item {
        display: grid !important;
        grid-template-columns: 25px 1fr;
        grid-template-rows: auto auto;
        grid-column-gap: 10px;
        grid-row-gap: 5px;
        &.unread {
            background-color: $color-list-item-active;
            &:hover {
                background-color: $color-list-item-active-hover;
            }
        }
        .avatar {
            grid-column: 1;
            grid-row: 1 / span 2;
            avatar .avatar {
                width: 25px;
                height: 25px;
                img {
                    width: 25px;
                    height: 25px;
                }
            }
        }
        .content {
            grid-column: 2;
            grid-row: 1;
        }
        .date {
            color: color-gray(50);
            font-size: $font-size-sm;
            grid-column: 2;
            grid-row: 2;
        }
    }
    .older-link {
        display: block;
        padding: 5px 0;
        font-size: $font-size-sm;
        text-align: center;
    }
    .blankslate {
        padding: 20px;
        border: 0;
        border-radius: 0;
        .blankslate-icon {
            margin: 0;
        }
    }
}
