@import 'list/list';
@import 'show/show';
@import 'progress/progress';
coaches {
    display: block;
    height: $full-container-height;
    padding-bottom: $beacon-padding;
    overflow: hidden;
}
coaches,
coaches-show {
    .progress {
        position: relative;
        height: $height-progress-bar;
        margin: 0;
        border-radius: $height-progress-bar;
        background: color-gray(35);
        .progress-bar {
            position: absolute;
            left: 0;
            line-height: $height-progress-bar;
            box-shadow: none;
            &--light {
                background: $color-progress;
            }
            &--dark {
                background: $color-progress-dark;
            }
        }
        .progress-text {
            position: absolute;
            right: 0;
            margin-right: 6px;
            color: $color-white;
            font-size: $font-size-sm;
            line-height: $height-progress-bar;
            text-align: right;
        }
    }
}
