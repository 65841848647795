.update-donations {
    .donation {
        td {
            transition: background-color 0.2s ease-out;
            cursor: pointer;
        }
        &:hover td {
            background-color: $color-list-item-hover;
        }
        &.active {
            td {
                background-color: $color-list-item-active;
            }
            &:hover td {
                background-color: $color-list-item-active-hover;
            }
        }
    }
    .text-line-through {
        text-decoration: line-through;
    }
}
