@import 'comment/comment';
@import 'contact/item';
$task-drawer-width: $side-drawer-width - 2px;
task-item-drawer {
    display: grid;
    width: 450px;
    height: 100%;
    grid-template-rows: auto $fr-1 auto;
    > .heading {
        padding-top: 8px;
        background-color: $color-white;
        > .horizontal-tab-sortable {
            .sortable-row > .as-sortable-item > .as-sortable-item-handle {
                cursor: pointer;
            }
        }
    }
    > .content {
        min-height: 20px;
        overflow-y: auto;
        > .contacts {
            padding: $container-padding;
        }
        > .comments {
            padding-bottom: 45px;
        }
    }
    > .comment-form {
        display: flex;
        position: fixed;
        bottom: $height-footer;
        align-items: flex-end;
        padding: 5px 100px 5px 10px;
        background-color: color-gray(90);
        textarea.form-control {
            width: 295px !important;
            margin-right: 5px;
        }
    }
}
