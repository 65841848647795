contacts-drawer {
    display: block;
    width: 350px;
    height: $full-container-height;
    border-left: 1px solid $color-border;
    overflow: auto;
    contact-info {
        > div.content {
            margin-bottom: 10px;
            padding: 0;
            background-color: $color-white;
            grid-template-rows: unset;
            grid-template-columns: unset;
            > div.address,
            > div.person {
                grid-row: auto;
                grid-column: auto;
            }
        }
    }
    .address {
        margin-bottom: 10px;
        font-size: 0.9rem;
        font-weight: 600;
    }
    contact-info-person {
        margin-bottom: 10px;
        grid-gap: 10px;
        grid-template-columns: unset;
        .static-field {
            font-weight: bold;
        }
    }
    contact-address {
        .wrapper {
            flex-direction: column;
            align-items: flex-start;
            padding-top: 0;
            border: 0;
            .content {
                flex-grow: 0;
                line-height: $font-size-sm * 1.8;
                h3 {
                    display: block;
                    max-height: 45px;
                    margin-bottom: 3px;
                    font-size: $font-size-sm;
                    font-weight: 600;
                    overflow: hidden;
                }
                .source {
                    display: none;
                }
            }
            .actions {
                a i {
                    margin-right: 2px;
                    font-size: $font-size-xs;
                }
                .primary {
                    display: none;
                }
            }
        }
    }
    .inline-input {
        tags-input {
            .tags {
                border: 0;
                border-bottom: 1px solid color-gray(90);
                border-radius: 0;
                .input {
                    padding-left: 0;
                    font-size: $font-size-sm;
                }
            }
            .autocomplete {
                border-radius: 0;
            }
        }
    }
    contact-notes {
        padding-bottom: 0;
    }
    .contact {
        display: grid;
        height: $full-container-height;
        grid-template-rows: auto $fr-1 auto;
        > .content {
            padding: 10px $container-padding;
            overflow: auto;
            > label {
                margin-top: 10px;
            }
        }
        > .footer {
            padding: 10px $container-padding;
            border-top: 1px solid $color-border;
        }
    }
    label {
        margin: 0 0 3px;
        color: color-gray(60);
        font-size: $font-size-xs;
        font-weight: normal;
    }
    .header {
        display: grid;
        align-items: center;
        height: 65px;
        padding: 0 0 0 $container-padding;
        border-bottom: 1px solid $color-border;
        font-size: $font-size-sm;
        grid-template-columns: 25px 1fr auto;
        grid-gap: 10px;
        avatar {
            .avatar,
            .avatar img {
                width: 25px;
                height: 25px;
            }
        }
        .actions {
            display: grid;
            padding: 14px 20px;
            border-left: 1px solid $color-border;
            font-size: $font-size-lg;
            grid-auto-flow: column;
            grid-gap: 10px;
            i {
                transition: color 0.2s;
                color: color-gray(60);
                cursor: pointer;
                &:hover {
                    color: color-gray(35);
                }
            }
        }
        .name {
            display: block;
            width: 225px;
            font-weight: bold;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
    .loading {
        display: grid;
        align-items: center;
        height: $full-container-height;
        color: $color-blue;
        justify-items: center;
    }
}
