tags {
    .periods {
        display: grid;
        margin: -$container-padding;
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
        .period {
            padding: $container-padding;
            border-right: 1px solid $color-border;
            &:last-child {
                border-right: 0;
            }
        }
    }
    .tags .tag {
        margin: 2px 0;
    }
    .tag-count {
        line-height: 28px;
    }
}
