@import 'modal/modal';
announcements {
    .announcement {
        display: grid;
        position: fixed;
        top: 55px;
        right: 0;
        left: 0;
        align-items: center;
        height: 70px;
        padding-left: $container-padding;
        transition: top 0.5s ease-in-out;
        border-bottom: 1px $color-border solid;
        background-color: $color-white;
        grid-template-columns: auto 1fr auto;
        grid-gap: 15px;
        &.ng-enter {
            top: -15px;
            transition-delay: 2s;
        }
        &.ng-enter.ng-enter-active {
            top: 55px;
        }
        &.ng-leave {
            top: 55px;
        }
        &.ng-leave.ng-leave-active {
            top: -15px;
        }
        > .content {
            text-overflow: ellipsis;
            white-space: nowrap;
            h3 {
                margin-top: 0;
                margin-bottom: 2px;
                font-size: $font-size-md;
            }
        }
        > .actions {
            .btn {
                margin-right: 10px;
                transition: border-color 0.2s;
                &:hover {
                    border-color: $color-border;
                }
            }
            .btn-icon {
                padding: 0 10px;
                border-color: transparent;
                background: transparent;
                font-size: 25px;
            }
        }
        > .close-box {
            display: grid;
            align-items: center;
            width: 70px;
            height: 100%;
            border-left: 1px $color-border solid;
            font-size: $font-size-lg;
            text-align: center;
            text-decoration: none;
            i {
                transition: opacity 0.2s;
                color: $color-text;
                opacity: 0.6;
            }
            &:hover {
                i {
                    opacity: 0.8;
                }
            }
        }
        &-info {
            border-bottom-color: $color-blue-border;
            background-color: $color-blue;
            color: $color-white;
            > .actions .btn:hover {
                border-color: $color-white;
                &.btn-icon {
                    color: $color-white;
                }
            }
            > .close-box {
                border-left-color: $color-blue-border;
                i {
                    color: $color-white;
                }
            }
        }
        &-warning {
            border-bottom-color: $color-warning-border;
            background-color: $color-warning;
            color: $color-text;
            > .actions .btn:hover {
                border-color: $color-text;
                &.btn-icon {
                    color: $color-text;
                }
            }
            > .close-box {
                border-left-color: $color-warning-border;
                i {
                    color: $color-text;
                }
            }
        }
        &-danger {
            border-bottom-color: $color-danger-dark-border;
            background-color: $color-danger-dark;
            color: $color-white;
            > .actions .btn:hover {
                border-color: $color-white;
                &.btn-icon {
                    color: $color-white;
                }
            }
            > .close-box {
                border-left-color: $color-danger-dark-border;
                i {
                    color: $color-white;
                }
            }
        }
        &-success {
            border-bottom-color: $color-success-dark-border;
            background-color: $color-success-dark;
            color: $color-white;
            > .actions .btn:hover {
                border-color: $color-white;
                &.btn-icon {
                    color: $color-white;
                }
            }
            > .close-box {
                border-left-color: $color-success-dark-border;
                i {
                    color: $color-white;
                }
            }
        }
    }
}
