appeal-sidebar {
    @extend .sidebar-expandable;
    > .content {
        @extend .sidebar;
        padding: 0;
    }
    .filters_section {
        padding: 10px $container-padding 18px;
        .filter--filtergroup_title {
            height: 22px;
            margin-bottom: 3px;
            padding: 0;
        }
        .btn {
            margin: 0;
        }
        .chosen-container-single > a {
            color: $color-white;
        }
    }
    .heading a {
        color: $color-white;
        text-decoration: none;
    }
}
