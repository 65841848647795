@import 'appointments/appointments';
@import 'commitments/commitments';
@import 'tags/tags';
@import 'weeklyReport/weeklyReport';
@import 'outstandingPledges/pledges';
@import 'outstandingSpecial/needs';
@import 'supportSummary/supportSummary';
@import 'progress/progress';
coaches-show {
    display: grid;
    height: $full-container-height;
    overflow: hidden;
    grid-template-columns: auto 1fr;
    .sidebar-expandable > .content {
        padding: 0;
        color: $color-white;
        font-size: $font-size-sm;
        > .heading {
            margin: 13px 0 0;
            padding: 10px $container-padding 15px;
            border-bottom: 1px solid $color-gray-blue;
            a,
            a:visited {
                color: $color-white;
                text-decoration: none;
            }
        }
        > .content {
            contact-person {
                display: block;
                width: 100%;
                padding: 0;
                border-radius: 1px;
                .wrapper {
                    display: block;
                    padding: 10px 0;
                    border-bottom: 1px solid $color-gray-blue;
                    border-top-width: 0;
                }
                h3 {
                    font-size: $font-size-md;
                }
                a,
                a:visited {
                    color: $color-white;
                }
                avatar {
                    display: block;
                    width: 100%;
                    margin-bottom: 5px;
                    padding: 0;
                    &,
                    .avatar,
                    .avatar img {
                        width: 25px;
                        height: 25px;
                    }
                }
                &:last-child .wrapper {
                    padding-bottom: 0;
                    border-bottom: 0;
                }
            }
            .info {
                width: 100%;
                padding: 10px;
                border-bottom: 1px solid $color-gray-blue;
                border-radius: 1px;
                .btn-group-justified {
                    display: flex;
                    margin-bottom: 10px;
                    button.btn {
                        flex-grow: 1;
                        transition: background-color 0.2s ease-out;
                    }
                    button.btn:focus {
                        outline: none;
                    }
                    button.btn.active {
                        background-color: $color-sidebar-bg;
                    }
                }
                .bold {
                    font-weight: 600;
                }
                ul {
                    margin-bottom: 0;
                }
            }
        }
    }
    .right-panel {
        display: grid;
        height: $full-container-height;
        padding: 0 $container-padding $beacon-padding; //odd scrollbar behavior without
        grid-template-columns: 1fr;
        grid-row-gap: 7.5px;
        overflow-y: auto;
        > .heading {
            display: grid;
            min-height: 65px;
            margin: 0 (-$container-padding) 7.5px;
            padding: 10px $container-padding 30px;
            border-bottom: 1px solid $color-border;
            grid-template-columns: 1fr auto;
            h3 {
                margin-top: 15px;
                margin-bottom: 15px;
                grid-column: 1;
            }
            coachee-progress {
                align-self: center;
                width: 450px;
                grid-column: 2;
            }
        }
        > .activities {
            margin-bottom: 30px;
            justify-items: center;
            h3 {
                margin-top: 0;
            }
            &.panel {
                > .panel-heading {
                    display: grid;
                    align-content: end;
                    padding: 0;
                    grid-template-columns: 1fr auto;
                    &:first-child {
                        padding-left: 10px;
                    }
                    > * {
                        align-self: center;
                        padding: 10px 0;
                    }
                    > .buttons {
                        display: grid;
                        padding: 0;
                        background-color: $btn-default-bg;
                        grid-gap: 0;
                        grid-template-rows: $fr-1;
                        grid-template-columns: repeat(3, auto);
                        > .btn {
                            display: flex;
                            align-items: center;
                            padding: 10px $container-padding;
                            border: 0;
                            border-left: 1px solid color-gray(85);
                            border-radius: 0;
                            line-height: 23px;
                            &:last-child {
                                padding-right: 20px;
                            }
                        }
                    }
                }
            }
            div.panel-body > div.activities-row {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                .phone,
                .e-contacts,
                .appointments,
                .correspondence {
                    display: grid;
                    grid-template-columns: auto 1fr;
                    grid-column-gap: 5px;
                }
                .phone,
                .e-contacts {
                    grid-template-rows: repeat(3, $fr-1);
                }
                .correspondence {
                    grid-template-rows: repeat(4, $fr-1);
                }
                .appointments {
                    grid-template-rows: $fr-1;
                }
            }
        }
        .panel {
            .blankslate {
                margin: -$container-padding;
                border: 0;
                border-radius: 0;
            }
        }
        > *:last-child {
            margin-bottom: 10px;
        }
    }
    @include respond-below(md) {
        .right-panel {
            > .heading {
                min-height: auto;
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: 0;
                grid-template-columns: 1fr;
                h3 {
                    margin: 0 (-$container-padding) 10px;
                    padding: 13px $container-padding 15px;
                    border-bottom: 1px solid $color-border;
                }
                coachee-progress {
                    width: auto;
                    grid-column: 1;
                }
            }
        }
    }
}
