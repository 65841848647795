@import 'modal/modal';
contact-address {
    .wrapper {
        display: flex;
        align-items: flex-end;
        padding: 10px 0;
        border-top: 1px solid $color-border;
        border-bottom: 1px solid $color-border;
        .content {
            flex-grow: 1;
            div:empty {
                display: none;
            }
            h3 {
                margin: 0;
                white-space: pre;
            }
        }
    }
    &:first-child {
        .wrapper {
            padding-top: 0;
            border-top: 0;
        }
    }
    .actions {
        a,
        a:link,
        a:visited {
            margin-right: 10px;
            cursor: pointer;
            &:hover {
                text-decoration: none;
            }
            i {
                margin-right: 5px;
            }
        }
    }
}
.contact-drawer {
    contact-address {
        .wrapper {
            flex-direction: column;
            align-items: flex-start;
            .content {
                flex-grow: 0;
            }
            .actions {
                margin-top: 10px;
            }
        }
    }
}
