appeals-flows-contact {
    display: block;
    transition: background-color 0.2s ease-out;
    background-color: $color-white;
    color: $color-text;
    font-size: $font-size-sm;
    cursor: move;
    user-select: none;
    .details {
        display: grid;
        padding: 10px;
        grid-template-columns: 25px 1fr 25px;
        grid-column-gap: 10px;
        .selector,
        .star {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .selector {
            transition: opacity 0.2s ease-out;
            color: $color-primary;
            opacity: 0;
            .unselected {
                display: block;
            }
            .selected {
                display: none;
            }
        }
        .info {
            overflow: hidden;
        }
        .name {
            color: $color-blue;
            font-weight: bold;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        &:hover {
            .selector {
                opacity: 1;
            }
        }
    }
    .pledge {
        display: grid;
        padding: 0 10px 5px;
        grid-template-columns: 1fr 1fr auto;
        grid-gap: 10px;
        .date {
            text-align: right;
            &.overdue {
                color: $color-danger;
            }
        }
        &.processed {
            .date.overdue {
                color: $color-text;
            }
        }
    }
    .excluded-appeal-contact {
        padding: 0 10px 5px 45px;
        ul {
            margin-bottom: 0;
        }
    }
    &:hover {
        background-color: $color-list-item-hover;
    }
    &.active,
    &.selected {
        background-color: $color-list-item-active;
        &:hover {
            background-color: $color-list-item-active-hover;
        }
    }
    &.selected {
        .selector {
            opacity: 1;
            .unselected {
                display: none;
            }
            .selected {
                display: block;
            }
        }
    }
}
