//using view width for units here due to odd sub-component stretching behavior
@import 'addresses/addresses';
@import 'details/details';
@import 'info/info';
@import 'notes/notes';
@import 'notices/notices';
@import 'people/people';
@import 'referrals/referrals';
@import 'tasks/tasks';
contact {
    $contact-content-min-width: 750px;
    $contact-drawer-width: 460px;
    $title-height: 61px;
    $chart-padding: 30px;
    $max-width-no-drawer: $sidebar-collapse-width + $contact-content-min-width + $contact-drawer-width;
    $max-width-no-drawer-with-filters: $max-width-no-drawer + $sidebar-width;
    display: block;
    height: 100%;
    > .contact-show {
        display: grid;
        height: $full-container-height;
        grid-column-gap: 0;
        grid-template-columns: 1fr auto;
        > .contact-content {
            display: grid;
            height: $full-container-height;
            grid-template-rows: auto $title-height $fr-1;
            > .contact-tabs {
                overflow: auto;
                > .horizontal-tab-sortable {
                    position: sticky;
                    top: -1px;
                    background-color: $color-blue-lighter;
                }
                .expand-link {
                    cursor: pointer;
                }
                .as-sortable-item.active-drawer {
                    display: none;
                }
                > .tab-content {
                    padding: 10px $container-padding;
                    background: $color-white;
                    overflow: auto;
                }
                donations-chart {
                    // chartjs workaround
                    canvas {
                        width: 100% !important;
                    }
                }
            }
        }
        > .contact-drawer {
            width: $contact-drawer-width;
            height: $full-container-height;
            transition: width 0.5s ease-out;
            overflow: hidden;
            .contact-drawer-container {
                display: grid;
                width: $contact-drawer-width;
                height: $full-container-height;
                border-left: 1px $color-menu-border solid;
                background: $color-white;
                grid-template-rows: $title-height $fr-1;
                .contact-drawer-title {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin: 0;
                    padding: 0;
                    border-bottom: 1px color-gray(85) solid;
                    line-height: 60px;
                    span {
                        flex-grow: 1;
                        padding-left: $container-padding;
                    }
                    .contact-drawer-title-close {
                        width: 70px;
                        border-left: 1px $color-menu-border solid;
                        text-align: center;
                        i {
                            transition: color 0.2s;
                            color: color-gray(60);
                            cursor: pointer;
                            &:hover {
                                color: color-gray(35);
                            }
                        }
                    }
                }
                .contact-drawer-content {
                    padding: $container-padding;
                    overflow-x: hidden;
                    overflow-y: auto;
                    tasks-list {
                        margin: -$container-padding;
                    }
                    > * {
                        display: block;
                        &.ng-leave {
                            animation: slide-left 0 reverse;
                        }
                    }
                }
            }
            &.ng-hide {
                width: 0;
            }
        }
    }
    @media only screen and (max-width: $max-width-no-drawer) {
        .contact-show.filters-collapsed {
            .contact-content .contact-tabs .horizontal-tab-sortable .sortable-row {
                .expand-link {
                    display: none;
                }
                .as-sortable-item.active-drawer {
                    display: block !important;
                }
            }
            .contact-drawer {
                width: 0;
            }
        }
    }
    @media only screen and (max-width: $max-width-no-drawer-with-filters) {
        .contact-show.filters-expanded {
            .contact-content .contact-tabs .horizontal-tab-sortable .sortable-row {
                .expand-link {
                    display: none;
                }
                .as-sortable-item.active-drawer {
                    display: block !important;
                }
            }
            .contact-drawer {
                width: 0;
            }
        }
    }
}
ui-view[data-ng-animate] {
    .contact-show .contact-drawer {
        transition: none;
    }
}
