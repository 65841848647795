.modal,
.modal.center {
    left: 50%;
    width: 600px;
    margin-bottom: 30px;
    transform: translateX(-50%) translateY(0%);
    transition: all 0.3s ease-out;
    overflow-y: auto;
    .modal-dialog {
        position: static;
        width: auto;
        min-width: 0;
        max-width: none;
        margin: 0;
        transform: none;
    }
    .modal-header {
        padding: 0;
        .modal-title {
            letter-spacing: 0.025em;
            line-height: 56px;
            text-align: center;
            text-transform: uppercase;
        }
        a.close {
            margin: 0;
            padding: 0 20px;
            transition: color 0.2s;
            border-left: 1px solid color-gray(90);
            color: color-gray(90);
            font-size: 25px;
            letter-spacing: 0.025em;
            line-height: 56px;
            opacity: 1;
            i.fa-angle-left {
                margin-left: -5px;
            }
            &:hover {
                color: color-gray(35);
            }
        }
    }
    .modal-content {
        border-radius: 0;
        box-shadow: none;
    }
    .modal-footer {
        padding: 15px;
        border-top: 1px solid color-gray(90);
        text-align: right;
        .btn + .btn {
            margin-bottom: 0;
            margin-left: 5px;
        }
        .btn-group .btn + .btn {
            margin-left: -1px;
        }
        .btn-block + .btn-block {
            margin-left: 0;
        }
        .btn-link {
            color: color-gray(35);
            &:hover {
                text-decoration: none;
            }
            &:focus {
                outline: none;
            }
        }
    }
    .modal-body .modal-footer {
        margin: 15px -15px -15px;
    }
    .row {
        margin-right: -5px;
        margin-left: -5px;
        [class*='col-'] {
            padding-right: 5px;
            padding-left: 5px;
        }
    }
    ul.nav.nav-tabs {
        margin: 10px -15px;
    }
    .form-group {
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .checkbox-label {
        display: inline-block;
    }
    small {
        font-size: 11px;
        font-weight: normal;
    }
}
// modal size (fullscreen)
.modal[size='fs'] {
    width: calc(100% - 20px);
    .modal-body {
        height: calc(100vh - 214px);
        overflow: auto;
    }
}
// modal slide
.modal {
    &.ng-enter {
        transform: translateX(-50%) translateY(-10%);
        opacity: 0;
    }
    &.ng-enter-active {
        transform: translateX(-50%) translateY(0%);
        opacity: 1;
    }
    &.ng-leave {
        transform: translateX(-50%) translateY(0%);
        opacity: 1;
    }
    &.ng-leave-active {
        transform: translateX(-50%) translateY(-10%);
        opacity: 0;
    }
}
