root {
    display: grid;
    max-width: 100vw; //use max instead of width to stop firefox scroll bug
    height: calc(100vh - #{$height-footer});
    grid-template-rows: auto $fr-1;
    > menu {
        grid-row: 1;
    }
    > div.root {
        overflow: hidden;
        grid-row: 2;
        > ui-view {
            display: block;
            height: 100%;
        }
    }
}
