@import 'filter/filter';
@import 'list/list';
@import 'search/search';
@import 'modals/modals';
tasks {
    display: grid;
    height: 100%;
    overflow: hidden;
    grid-template-rows: 100%;
    grid-template-columns: auto auto 1fr;
    > tasks-filter {
        grid-column: 2;
    }
    > tasks-list {
        width: 100%;
        overflow: hidden;
        grid-column: 3;
    }
}
