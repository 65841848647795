contact-person {
    .wrapper {
        display: flex;
        padding: 10px 0;
        border-top: 1px solid $color-border;
        border-bottom: 1px solid $color-border;
        avatar {
            padding-right: 10px;
        }
        .content {
            flex-grow: 1;
            h3 {
                margin: 0;
            }
            .show-additional {
                margin-left: 5px;
                cursor: pointer;
            }
        }
        &.merging {
            cursor: pointer;
        }
        &.selected {
            background-color: $color-bg;
        }
    }
    &:first-child {
        .wrapper {
            padding-top: 0;
            border-top: 0;
        }
    }
    .actions {
        align-self: flex-end;
        a,
        a:link,
        a:visited {
            margin-right: 10px;
            cursor: pointer;
            &:last-child {
                margin-right: 0;
            }
            &:hover {
                text-decoration: none;
            }
            i {
                margin-right: 5px;
            }
        }
    }
}
.contact-drawer {
    contact-person {
        .wrapper {
            flex-direction: column;
            .content {
                flex-grow: 0;
            }
            .actions {
                align-self: flex-start;
                margin-top: 10px;
            }
        }
    }
}
