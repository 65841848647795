$contact-viewport-width: calc(100vw - #{$sidebar-width});
$search-bar-width: 275px;
@import 'sidebar/sidebar';
@import 'item/item';
analysis {
    display: grid;
    overflow: hidden;
    grid-template-columns: auto 1fr auto;
    > .results {
        width: 100%;
        overflow: hidden;
        > div {
            @extend .lists;
            display: grid;
            height: $full-container-height;
            overflow-y: auto;
            grid-template-rows: auto auto auto $fr-1;
            > div.menu {
                grid-column-gap: 10px;
                grid-template-columns: auto 1fr $search-bar-width;
                grid-column: 1;
                grid-row: 1;
                &.filters-expanded {
                    min-width: 425px; // sanity width
                    @media (max-width: $contact-menu-breakpoint) {
                        grid-template-columns: 1fr auto;
                        grid-template-rows: $fr-1 $fr-1;
                        grid-row-gap: 5px;
                        > .selected-count {
                            grid-row: 2;
                            grid-column: 1;
                        }
                        > .search-bar {
                            width: 100%;
                            grid-column: 1 / span 2;
                        }
                        > div.menu-buttons {
                            width: 100%;
                            grid-row: 2;
                            grid-column: 2;
                        }
                    }
                    @media (min-width: $contact-menu-breakpoint) {
                        > div.menu-buttons .show-small {
                            display: none;
                        }
                    }
                }
                &.filters-collapsed {
                    $breakpoint: 810px; // calculation wraps early - slightly more than $contact-menu-breakpoint - $sidebar-width + $sidebar-collapse-width
                    min-width: 510px; // sanity width
                    @media (max-width: $breakpoint) {
                        grid-template-columns: 1fr auto;
                        grid-template-rows: $fr-1 $fr-1;
                        grid-row-gap: 5px;
                        > .selected-count {
                            grid-row: 2;
                            grid-column: 1;
                        }
                        > .search-bar {
                            width: 100%;
                            grid-column: 1 / span 2;
                        }
                        > div.menu-buttons {
                            width: 100%;
                            grid-row: 2;
                            grid-column: 2;
                        }
                    }
                    @media (min-width: $breakpoint) {
                        > div.menu-buttons .show-small {
                            display: none;
                        }
                    }
                }
                > .search-bar {
                    width: $search-bar-width;
                    .input-group-addon {
                        padding-right: 0;
                        background-color: $color-white;
                    }
                    input {
                        border-left-width: 0;
                    }
                }
                > div.menu-buttons {
                    display: grid;
                    grid-template-columns: 1fr auto;
                }
            }
            > pagination-sub-menu {
                grid-row: 2;
                grid-column: 1;
            }
            > div.sub-menu {
                grid-row: 3;
                grid-column: 1;
                div.sub-line-2.analysis {
                    display: grid;
                    border-bottom: 1px solid $color-border;
                    font-size: $font-size-sm;
                    grid-template-columns: 2fr repeat(3, 1fr) 1px repeat(3, 1fr);
                    a {
                        display: flex;
                        align-items: center;
                        padding: 5px $container-padding;
                        user-select: none;
                        div:first-child {
                            flex-grow: 1;
                        }
                        &:hover {
                            text-decoration: none;
                        }
                        &.filtered {
                            transition: background-color 0.2s ease-out;
                            background-color: $color-list-item-hover;
                            &:hover {
                                background-color: $color-list-item-active;
                            }
                        }
                    }
                    .divider {
                        height: 100%;
                        padding: 0;
                        border-left: 2px dotted $color-border;
                    }
                }
            }
            > div.content {
                overflow: auto;
                grid-row: 4;
                grid-column: 1;
                .list-group-item {
                    padding: 0;
                    border-right: 0;
                    border-left: 0;
                    border-radius: 0;
                    &:first-child {
                        border-top-width: 0;
                    }
                }
            }
        }
    }
}
