analysis-sidebar {
    display: grid;
    height: $full-container-height;
    background-color: $color-filter-bar;
    grid-template-columns: $sidebar-collapse-width auto;
    grid-template-rows: $fr-1;
    > .filter-collapse {
        border-right: 1px solid $color-gray-blue;
        color: $color-white;
        cursor: pointer;
        grid-column: 1;
        i {
            position: relative;
            top: 50%;
            margin-left: 3px;
            transition: transform 0.2s ease-out;
        }
    }
    > .content {
        width: $sidebar-width;
        opacity: 1;
        overflow-y: auto;
        grid-column: 2;
        &.ng-hide {
            width: 0;
            opacity: 0;
        }
        &.ng-hide-add {
            transition:
                opacity 0.2s ease-out,
                width 0.5s ease-out 0.4s;
        }
        &.ng-hide-remove {
            transition:
                width 0.5s ease-out,
                opacity 0.2s ease-out 0.7s;
        }
        > .heading {
            margin: 13px 0 10px;
            padding: 10px $container-padding 15px;
            border-bottom: 1px solid $color-gray-blue;
            a,
            a:visited {
                color: $color-white;
                text-decoration: none;
            }
        }
        .contact-drawer {
            display: grid;
            grid-template-rows: auto $fr-1;
            > div.edit-filters {
                padding: 0 $container-padding 10px;
                color: $color-white;
                line-height: 29px;
                .btn-group-justified {
                    display: flex;
                    button.btn {
                        flex-grow: 1;
                        transition: background-color 0.2s ease-out;
                    }
                    button.btn:focus {
                        outline: none;
                    }
                    button.btn.active {
                        background-color: $color-sidebar-bg;
                    }
                }
            }
            > div.list {
                grid-row: 2 / 2;
            }
        }
    }
    .report-filter {
        padding: 10px 0;
        border-top: 1px solid $color-gray-blue;
        label {
            font-weight: normal;
        }
        .filter_toggle_label {
            display: grid;
            align-items: center;
            cursor: pointer;
            grid-template-columns: 1fr auto;
            i {
                transition: transform 0.2s ease-out;
            }
        }
        .filter_toggle_actions {
            display: grid;
            grid-template-columns: 12px 12px;
            grid-gap: 10px;
            > i.reverse-filter {
                transition: all 0.2s ease-out;
                &.reverse {
                    color: $color-filter-reverse;
                }
            }
        }
        .filter_text {
            height: 50px;
            transition: height 0.2s ease-out;
            overflow: hidden;
            &.ng-hide {
                height: 0;
            }
        }
    }
    .rzslider {
        margin-top: 30px;
        .rz-bar {
            height: 4px;
            background-color: $color-filter-select-bg;
        }
        .rz-selection {
            background: $color-white;
        }
        .rz-pointer {
            top: auto;
            bottom: -6px;
            width: 16px;
            height: 16px;
            outline: none;
            background-color: $color-gold;
            &::after {
                display: none;
            }
            &:hover {
                background-color: $color-orange;
            }
        }
        .rz-bubble {
            bottom: 14px;
            color: $color-white;
            &.rz-limit {
                color: color-gray(60);
            }
        }
    }
    .reverse {
        .rzslider {
            .rz-bar {
                background: $color-white;
            }
            .rz-selection {
                background-color: $color-filter-select-bg;
            }
        }
    }
}
