preferences-organizations-contacts {
    display: grid;
    height: $full-container-height;
    margin: -10px -15px -60px;
    grid-template-rows: auto $fr-1;
    > .menu {
        display: grid;
        align-items: center;
        height: 65px;
        padding: 0 $container-padding;
        transition: background-color 0.2s ease-out;
        border-bottom: 1px solid $color-border;
        grid-template-columns: 1fr auto auto;
        grid-column-gap: 10px;
        .search {
            width: 275px;
            .input-group-addon {
                padding-right: 0;
                background: $color-white;
            }
            .search-filter {
                border-left: 0;
            }
        }
        &.selected {
            background-color: $color-nav-selected;
            .section {
                > a.menu-item {
                    transition: color 0.2s ease-out;
                    color: $color-white;
                    &:hover {
                        background-color: $color-nav-selected-dark;
                    }
                }
                &.active {
                    > a.menu-item {
                        background-color: $color-nav-selected-dark;
                    }
                }
            }
        }
        .section {
            &-field {
                height: 35px;
                color: $color-white;
                font-size: $font-size-sm;
                .chosen-choices {
                    width: 250px;
                    max-height: 34px;
                    transition: max-height 0.2s;
                    overflow-y: auto;
                }
                .chosen-container-active {
                    .chosen-choices {
                        max-height: 250px;
                    }
                }
            }
            &-selector {
                padding: $container-padding 10px;
                color: $color-white;
                text-align: right;
                > a {
                    color: $color-white;
                }
            }
        }
    }
    .contacts {
        padding-bottom: $beacon-padding;
        overflow: auto;
    }
    .blankslate {
        margin: $container-padding;
    }
    .contact {
        display: grid;
        padding: 10px $container-padding;
        transition: background-color 0.2s;
        border-bottom: 1px solid $color-border;
        font-size: $font-size-sm;
        grid-gap: 10px;
        grid-template-columns: 25px 1fr 1fr 120px;
        &:hover {
            background-color: $color-list-item-hover;
        }
        avatar .avatar,
        avatar .avatar img {
            width: 25px;
            height: 25px;
        }
        .name {
            margin: 0;
            font-size: $font-size-md;
            font-weight: 600;
        }
        .address {
            margin: 0;
        }
    }
}
