login {
    display: grid;
    width: 100vw;
    height: calc(100vh - #{$height-footer});
    grid-template-rows: auto $fr-1;
    > menu {
        border-bottom: 1px solid $color-border;
        grid-row: 1;
    }
    > cover {
        overflow: hidden;
        grid-row: 2;
        > ui-view {
            display: block;
            height: 100%;
        }
    }
    .btn.btn-login {
        padding: 30px;
        white-space: normal;

        img {
            display: inline-block;
            height: 40px;
            margin-bottom: 20px;
        }
    }
    .brand {
        width: 100%;
        height: 80px;
        background-color: $color-primary;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 130px;
    }
    .main-copyright {
        a,
        a:link {
            color: $color-text;
        }
    }
    .cover-item {
        min-width: 500px;
        border-top: 0;
    }
    h3 {
        margin-top: 0;
    }
    .panel {
        border: 0;
    }
    .panel-footer {
        padding-top: $gutter;
        padding-bottom: $gutter;
        border: 0;
        border-top: 3px solid $color-secondary;
        background-color: color-gray(95);
        p:last-child {
            margin-bottom: 0;
        }
    }
    .panel-body {
        padding-top: $gutter;
        padding-right: $gutter;
        padding-bottom: $gutter;
        padding-left: $gutter;
    }
}

@media (max-width: $screen-sm-max) {
    login {
        .cover {
            background-color: color-gray(95);
            background-image: none !important;
            .cover-item {
                min-width: auto;
                max-width: auto;
                margin: 0;
            }
        }
    }
}
