.toast-top-right {
    top: 5px;
    right: 0;
}
.toast-container {
    position: fixed;
    > .toast {
        position: relative;
        width: 300px;
        transition: opacity 0.2s ease-out;
        border-bottom: 1px $color-border solid;
        color: $color-white;
        opacity: 0.9;
        user-select: none;
        &:hover {
            opacity: 1;
        }
        .heading {
            display: grid;
            border-bottom: 1px $color-border solid;
            font-size: $font-size-lg;
            line-height: 60px;
            grid-template-columns: auto 70px;
            .toast-title {
                padding: 0 $container-padding;
                font-weight: normal;
            }
            .toast-close {
                width: 70px;
                border-left: 1px $color-border solid;
                text-align: center;
                i {
                    transition: color 0.2s;
                    color: color-gray(90);
                    &:hover {
                        color: $color-white;
                    }
                }
            }
        }
        .toast-message {
            padding: 10px $container-padding $container-padding;
        }
    }
    > .toast-error {
        border-color: $color-danger-dark-border;
        background-color: $color-danger-dark;
        .heading {
            border-color: $color-danger-dark-border;
            .toast-close {
                border-color: $color-danger-dark-border;
            }
        }
    }
    > .toast-success {
        border-color: $color-success-dark-border;
        background-color: $color-success-dark;
        .heading {
            border-color: $color-success-dark-border;
            .toast-close {
                border-color: $color-success-dark-border;
            }
        }
    }
    > .toast-warning {
        border-color: $color-warning-dark-border;
        background-color: $color-warning-dark;
        .heading {
            border-color: $color-warning-dark-border;
            .toast-close {
                border-color: $color-warning-dark-border;
            }
        }
    }
    > .ng-enter {
        animation: 0.7s slide-down-and-left ease-out;
    }
    > .ng-leave {
        animation: 0.7s slide-right-and-up ease-out;
    }
}
@keyframes slide-down-and-left {
    0% {
        right: -300px;
        max-height: 0;
    }
    50% {
        right: -300px;
        max-height: 300px;
    }
    100% {
        right: 0;
        max-height: 300px;
    }
}
@keyframes slide-right-and-up {
    0% {
        right: 0;
        max-height: 300px;
    }
    50% {
        right: -300px;
        max-height: 300px;
    }
    100% {
        right: -300px;
        max-height: 0;
    }
}
