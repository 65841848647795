share-preferences {
    .account-users {
        padding-left: 1.5em;
        td,
        th {
            padding: 10px;
            border: 1px solid $color-bg;
        }
    }
}
