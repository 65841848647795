@import 'appeals/appeals';
@import 'correspondence/correspondence';
coaches-show-progress {
    .progress-heading {
        padding-bottom: $container-padding;
        .progress-title {
            margin: 0;
            line-height: 33px;
        }
    }
    > .panel {
        > .panel-heading {
            display: grid;
            align-content: end;
            padding: 0;
            grid-template-columns: 1fr 1fr auto auto;
            &:first-child {
                padding-left: 10px;
            }
            > * {
                align-self: center;
                padding: 10px 0;
            }
            > .buttons {
                display: grid;
                padding: 0;
                background-color: $btn-default-bg;
                grid-template-columns: repeat(2, auto);
                grid-template-rows: $fr-1;
                grid-gap: 0;
                > .btn {
                    display: flex;
                    align-items: center;
                    padding: 10px $container-padding;
                    border: 0;
                    border-left: 1px solid color-gray(85);
                    border-radius: 0;
                    line-height: 23px;
                }
            }
            > .help {
                padding: 0 15px;
            }
        }
        > .panel-body {
            padding: 0;
            .blankslate {
                height: 501px;
                margin: 0;
                border-radius: 0;
            }
        }
        .progress-content {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: $fr-1;
            grid-gap: 0;
            > * {
                min-height: 180px;
                border-right: 1px solid $color-panel-border;
                border-bottom: 1px solid $color-panel-border;
                border-radius: 1px;
                &:nth-child(3),
                &:nth-child(6) {
                    border-right: 0;
                }
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6) {
                    border-bottom: 0;
                }
                .progress-component {
                    display: grid;
                    height: 100%;
                    font-size: $font-size-sm;
                    grid-template-rows: 142px auto;
                    > .header {
                        height: 142px;
                        padding-top: 30px;
                        padding-bottom: 10px;
                        text-align: center;
                        justify-self: center;
                        .panel-title {
                            font-size: $font-size-md;
                            font-weight: 600;
                        }
                        i {
                            padding-bottom: 10px;
                        }
                    }
                    > .statistics {
                        display: grid;
                        height: 72px;
                        padding: 10px 0;
                        text-align: center;
                        white-space: nowrap;
                        grid-template-columns: repeat(3, 1fr);
                        grid-template-rows: $fr-1;
                        > * {
                            align-self: center;
                            min-height: 40px;
                            padding-top: 5px;
                            padding-bottom: 5px;
                            a {
                                display: block;
                            }
                        }
                        .item {
                            border-right: 1px solid color-gray(80);
                            text-align: center;
                            > a {
                                display: inline-block;
                            }
                            &:last-child {
                                border-right: 0;
                            }
                        }
                    }
                }
            }
            h3 {
                margin: 0;
                font-size: $font-size-md;
            }
        }
        a:hover {
            text-decoration: none;
        }
    }
    @include respond-below(md) {
        > .panel {
            .progress-content {
                grid-template-columns: repeat(2, 1fr);
                > * {
                    border: 0;
                    border-right: 1px solid $color-panel-border;
                    border-bottom: 1px solid $color-panel-border;
                    &:nth-child(3) {
                        border-right: 1px solid $color-panel-border;
                    }
                    &:nth-child(4) {
                        border-bottom: 1px solid $color-panel-border;
                    }
                    &:nth-child(2),
                    &:nth-child(4),
                    &:nth-child(6) {
                        border-right: 0;
                    }
                    &:nth-child(5),
                    &:nth-child(6) {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
    @include respond-below(sm) {
        > .panel {
            .progress-content {
                grid-template-columns: 1fr;
                > * {
                    border: 0;
                    border-bottom: 1px solid $color-panel-border;
                    &:nth-child(4),
                    &:nth-child(5) {
                        border-bottom: 1px solid $color-panel-border;
                    }
                }
            }
        }
    }
}
