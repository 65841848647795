contact-recommendation {
    display: block;
    margin-top: 30px;
    > div {
        display: grid;
        grid-column-gap: 20px;
        grid-template-columns: repeat(2, 1fr);
        h5 {
            margin-bottom: 5px;
            grid-column: 1 / span 2;
        }
        .blankslate {
            grid-column: 1 / span 2;
        }
    }
}
