alert-message {
    .MuiAlert {
        align-items: center;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: rgb(255, 244, 229);
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        padding: 6px 16px;
        color: rgb(102, 60, 0);
        position: fixed;
        top: 55px;
        width: 100%;
        max-width: none;
        z-index: 10;

        &-icon {
            margin-right: 12px;
            padding: 7px 0;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            opacity: 0.9;
            color: #ff9800;
        }

        &-svg-icon {
            user-select: none;
            width: 20px;
            height: 20px;
            display: inline-block;
            fill: currentColor;
            -webkit-flex-shrink: 0;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            font-size: inherit;
        }

        &-message {
            padding: 8px 0;
            min-width: 0;
            overflow: auto;
            font-size: 14px;
            text-align: left;
            max-width: none;
            width: 100%;
        }

        &-action {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-align-items: flex-start;
            -webkit-box-align: flex-start;
            -ms-flex-align: flex-start;
            align-items: flex-start;
            padding: 4px 0 0 16px;
            margin-left: auto;
            margin-right: -8px;

            button {
                display: -webkit-inline-box;
                display: -webkit-inline-flex;
                display: -ms-inline-flexbox;
                display: inline-flex;
                -webkit-tap-highlight-color: transparent;
                border: 0;
                background: transparent;
                cursor: pointer;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                vertical-align: middle;
                -webkit-text-decoration: none;
                text-decoration: none;
                text-align: center;
                -webkit-flex: 0 0 auto;
                -ms-flex: 0 0 auto;
                flex: 0 0 auto;
                border-radius: 50%;
                overflow: visible;
                color: inherit;
                padding: 5px;

                span {
                    overflow: hidden;
                    pointer-events: none;
                    position: absolute;
                    z-index: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    border-radius: inherit;
                }
            }
        }
    }
}
