menu-account-lists {
    .title {
        padding: 5px 15px;
        border-top: 1px $color-border solid;
        color: $text-muted;
        font-size: $font-size-sm;
        font-weight: bold;
        text-transform: uppercase;
    }
    .list-group {
        margin-bottom: 0;
        border-top: 1px $color-border solid;
        > .list-group-item {
            display: grid;
            align-items: center;
            padding: 10px 15px;
            border-right: 0;
            border-left: 0;
            font-size: $font-size-sm;
            grid-gap: 10px;
            grid-template-columns: 1fr auto;
            &:first-child {
                border-top: 0;
            }
            &:last-child {
                border-bottom: 0;
            }
            &:first-child,
            &:last-child {
                border-radius: 0;
            }
        }
    }
}
